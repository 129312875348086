import type {ValueOf} from '@myparcel-frontend/types';

export const Hosts = {
  MyParcelNl: 'myparcel.me',
  MyParcelBe: 'sendmyparcel.me',
  Flespakket: 'flespakket.me',
  LocalHost: 'localhost',
  Default: 'default',
} as const;

export const ConfigKey = {
  Default: 'default',
  MyParcelNl: 'myparcel-nederland',
  MyParcelBe: 'myparcel-belgie',
  Flespakket: 'flespakket',
} as const;

const HOST_TO_CONFIG_KEY = {
  [Hosts.MyParcelNl]: ConfigKey.MyParcelNl,
  [Hosts.MyParcelBe]: ConfigKey.MyParcelBe,
  [Hosts.LocalHost]: ConfigKey.MyParcelNl,
  [Hosts.Flespakket]: ConfigKey.Flespakket,
  [Hosts.Default]: ConfigKey.MyParcelNl,
} as const;

export const hostToConfigKey = (host: ValueOf<typeof Hosts>): ValueOf<typeof ConfigKey> => {
  return HOST_TO_CONFIG_KEY[host];
};
