import type {Language} from '@/types/i18n.types';
import type {ValueOf} from '@myparcel-frontend/types';
import type {I18n} from 'vue-i18n';
import {ref} from 'vue';
import {createI18n} from 'vue-i18n';
import DEFAULT_MESSAGES from '@/locales/nl_NL.json';

export const loadedLanguages = ref<ValueOf<typeof Language>[]>([]);

export const i18nInstance = ref<I18n>();

export const getLocaleCode = (
  language: string | ValueOf<typeof Language>,
  {
    defaultLanguage,
    supportedLanguages,
  }: {
    defaultLanguage: ValueOf<typeof Language>;
    supportedLanguages: ValueOf<typeof Language>[];
  },
): ValueOf<typeof Language> => {
  let languageCode: string | ValueOf<typeof Language> = language;

  // eslint-disable-next-line no-magic-numbers
  if (languageCode.length === 2) {
    languageCode = supportedLanguages.find((locale) => locale.startsWith(languageCode)) || defaultLanguage;
  }

  languageCode = languageCode.replace('-', '_');
  const isSupported = supportedLanguages.includes(languageCode as ValueOf<typeof Language>);

  if (!isSupported) {
    languageCode = defaultLanguage as ValueOf<typeof Language>;
  }

  return languageCode as ValueOf<typeof Language>;
};

export const loadLanguageTranslations = async (locale: ValueOf<typeof Language>) => {
  if (!i18nInstance.value || loadedLanguages.value.includes(locale)) {
    return;
  }

  /* c8 ignore next - import is not fully resolved since it is mocked */
  const messages = await import(`./locales/${locale}.json`);
  i18nInstance.value.global.setLocaleMessage(locale, messages.default);
  loadedLanguages.value.push(locale);
};

export const createI18nWithLocales = ({
  defaultLanguage,
  fallbackLanguage,
  supportedLanguages,
}: {
  defaultLanguage: ValueOf<typeof Language>;
  fallbackLanguage: ValueOf<typeof Language>;
  supportedLanguages: ValueOf<typeof Language>[];
}): I18n => {
  const urlParams = new URLSearchParams(window.location.search);
  const language = urlParams.get('lang') || window.navigator.language;
  const locale = getLocaleCode(language, {
    defaultLanguage,
    supportedLanguages,
  });

  i18nInstance.value = createI18n({
    legacy: false,
    locale,
    defaultLanguage,
    fallbackLocale: fallbackLanguage,
    messages: {
      [defaultLanguage]: DEFAULT_MESSAGES,
    },
  });

  loadedLanguages.value.push(defaultLanguage);

  void loadLanguageTranslations(locale);
  return i18nInstance.value;
};

export const loadAndSetLanguage = async (locale: ValueOf<typeof Language>) => {
  if (loadedLanguages.value.includes(locale)) {
    return;
  }

  await loadLanguageTranslations(locale);
};

export const getCurrentLanguage = (): ValueOf<typeof Language> => {
  return i18nInstance.value?.global.locale;
};
