<template>
  <Dropdown
    v-model="language"
    name="language-toggle"
    :title="$t('locales.select')"
    :options="options">
    <template #value="{option}">
      <div class="flex gap-2">
        <FlagIcon :country="option!.label" />
        {{ $t(`locales.${option?.label}`) }}
      </div>
    </template>
    <template #option="{option}">
      <div class="flex gap-2">
        <FlagIcon :country="option!.label" />
        {{ $t(`locales.${option?.label}`) }}
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import {FlagIcon} from '@myparcel-frontend/ui';
import {ref, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import {useBranding} from '@/composables/useBranding/useBranding';
import {useConfig} from '@/composables/useConfig/useConfig';
import {loadAndSetLanguage} from '@/i18n';

const {locale} = useI18n({useScope: 'global'});
const {getBranding} = useBranding();
const {config, subdomain} = useConfig();

const language = ref(locale.value);
const options = ref(
  config.value?.supportedLanguages.map((languageKey) => ({
    label: languageKey,
    value: languageKey,
  })),
);

watch(locale, () => {
  language.value = locale.value;
});

watch(language, async () => {
  await Promise.all([
    loadAndSetLanguage(language.value),
    getBranding({
      subdomain: subdomain.value,
      language: language.value,
    }),
  ]);

  locale.value = language.value;
  const html = document.querySelector('html');

  if (html) {
    html.setAttribute('lang', language.value);
  }
});
</script>
