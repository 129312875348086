import {createWebHistory, createRouter} from 'vue-router';
import {subdomainGuard} from './guards/subdomain';
import {routes} from './routes';

export const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
});

router.beforeEach(subdomainGuard);
