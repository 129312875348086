import type {ConfigKey, Hosts} from '@/types/platform.types';
import type {ValueOf} from '@myparcel-frontend/types';
import {hostToConfigKey} from '@/types/platform.types';

/**
 * Get the config for the current domain.
 * If there are multiple chained subdomains, try to match the longest one.
 * If no config is found, return the default config.
 *
 * @param hostMapping - The mapping fetched by fetchMapping() above.
 * @param hostName - The hostname of the current page.
 * @returns The config key for the current page.
 */
export const hostNameMatcher = (hostName: ValueOf<typeof Hosts> | string): ValueOf<typeof ConfigKey> => {
  const hostNameWithoutPort = hostName.split(':').shift()!;
  /* @ts-expect-error - hostname can also be string that is not in the configKeys */
  const configKey = hostToConfigKey(hostNameWithoutPort);

  if (configKey) {
    return configKey;
  }

  const slices = hostNameWithoutPort.split('.');

  if (slices.length === 1) {
    throw new Error(`No config found for hostname: ${hostNameWithoutPort}`);
  }

  const subHost = slices.splice((slices.length - 1) * -1);
  return hostNameMatcher(subHost.join('.'));
};
