<template>
  <div class="flex gap-2 justify-center">
    <ExternalLinkAnchor
      v-for="platform in filteredSocialLinks"
      :key="platform.social"
      :target="Target.Blank"
      :variant="ExternalLinkVariant.Social"
      :href="platform.url">
      <SocialIcon :name="platform.social" />
    </ExternalLinkAnchor>
  </div>
</template>

<script setup lang="ts">
import type {ConsumerPortalFields} from '@myparcel-frontend/types';
import {ExternalLinkAnchor, ExternalLinkVariant, Target} from '@myparcel-frontend/ui';
import {computed} from 'vue';
import SocialIcon from '@/components/SocialIcon/SocialIcon.vue';

const props = defineProps<{
  socialLinks: ConsumerPortalFields['social_links'];
}>();

const socialPrefix = {
  facebook: 'https://facebook.com/',
  twitter: 'https://x.com/',
  linkedin: 'https://linkedin.com/company/',
  instagram: 'https://www.instagram.com/',
  youtube: 'https://www.youtube.com/@',
  tiktok: 'https://www.tiktok.com/@',
};

const filteredSocialLinks = computed(() => {
  return Object.entries(props.socialLinks)
    .filter(([, url]) => url)
    .map(([social, url]) => ({
      social,
      url: socialPrefix[social] + url,
    }));
});
</script>
