import type {ConsumerPortalFields, ShopBranding} from '@myparcel-frontend/types';
import {SocialWidgetType} from '@myparcel-frontend/types';
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import {useEnv} from '@/composables/useEnv/useEnv';
import {getCurrentLanguage} from '@/i18n';

export const useBrandingStore = defineStore('branding', () => {
  const branding = ref<ShopBranding>();
  const fields = ref<Record<string, ConsumerPortalFields>>({});

  const {getAssetUrl} = useEnv();

  const setBranding = (newBranding: ShopBranding) => {
    branding.value = newBranding;
  };

  const setFields = (language: string, newFields: ConsumerPortalFields) => {
    fields.value[language] = newFields;
  };

  const logo = computed(() => {
    if (!branding.value) {
      return '';
    }

    return getAssetUrl(branding.value.logo);
  });

  const currentLanguageFields = computed(() => {
    const language = getCurrentLanguage();
    return fields.value[language];
  });

  const hasSocialLinks = computed(() => {
    if (!currentLanguageFields.value) {
      return false;
    }

    return Object.values(currentLanguageFields.value.social_links).some(Boolean);
  });

  const hasBanner = computed(() => {
    const hasImage = currentLanguageFields.value?.banner_path;
    const hasAction =
      currentLanguageFields.value.banner_button_enabled && currentLanguageFields.value.banner_action_url;
    return Boolean(hasImage || hasAction || currentLanguageFields.value.banner_action_title);
  });

  const hasSocialWidget = computed(() => {
    return currentLanguageFields.value.social_widget.type !== SocialWidgetType.None;
  });

  const bannerUrl = computed(() => {
    const image = currentLanguageFields.value?.banner_path;

    if (!image) {
      return '';
    }

    if (typeof image === 'string') {
      return getAssetUrl(image);
    }

    return URL.createObjectURL(image);
  });

  return {
    branding,
    fields,
    logo,
    currentLanguageFields,
    hasSocialLinks,
    hasSocialWidget,
    hasBanner,
    bannerUrl,
    setBranding,
    setFields,
  };
});
