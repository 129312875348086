import type {ValueOf} from '@myparcel-frontend/types';
import {Hosts} from '@/types/platform.types';

/**
 * Get the hostname of the page. Overriden by environment variable if set.
 *
 * @returns The hostname of the page.
 */
export const getHostName = (): ValueOf<typeof Hosts> => {
  // do NOT change to env.hostname or something, as this is only for development.
  if (import.meta.env.VITE_APP_HOSTNAME) {
    return import.meta.env.VITE_APP_HOSTNAME;
  }

  if (window?.location?.host) {
    return window.location.host as ValueOf<typeof Hosts>;
  }

  return Hosts.Default;
};
