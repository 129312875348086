import { ref as c, readonly as I } from "vue";
var d = {
  Info: "info",
  Success: "success",
  Warning: "warning",
  Error: "alert"
}, g = {
  Toaster: "toaster"
};
const o = c([]), r = c(0), E = 6e3;
function m() {
  const s = ({
    id: t,
    variant: n = d.Info,
    canClose: u,
    withShadow: l,
    body: v,
    title: N,
    timeout: a = E,
    location: T = g.Toaster
  }) => {
    let i = t;
    i || (r.value++, i = r.value.toString()), o.value.unshift({
      id: i,
      body: v,
      variant: n,
      title: N,
      timeout: a,
      location: T,
      canClose: u,
      withShadow: l
    }), a && setTimeout(() => e(i), a);
  }, e = (t) => {
    o.value = o.value.filter((n) => n.id !== t);
  }, f = () => {
    o.value = [];
  };
  return {
    notifications: I(o),
    addNotification: s,
    removeNotification: e,
    clearNotifications: f
  };
}
export {
  m as useNotification
};
