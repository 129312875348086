<template>
  <div
    :class="className"
    :role="attrs.role">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type {ClassValue} from '@myparcel-frontend/utils';
import {cn} from '@myparcel-frontend/utils';
import {computed, useAttrs} from 'vue';

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();

const className = computed(() =>
  cn(
    'bg-white border border-slate-200 p-6 rounded-lg shadow-card w-full overflow-hidden',
    attrs.class as ClassValue | undefined,
  ),
);
</script>
