/* c8 ignore start */
import type {Environment} from '@/types/environment.types';

export default async (): Promise<void> => {
  const globalConfig = new Map();
  const envFile = await fetch('/env/env.json');
  const notFound = 404;

  if (envFile.status === notFound) {
    return;
  }

  const envVariables = (await envFile.json()) as Environment;

  for (const key in envVariables) {
    const value = envVariables[key as keyof Environment];

    if (!value) {
      return;
    }

    globalConfig.set(screamingSnakeCaseToCamelCase(key), value);
  }

  // @ts-expect-error - this variable is set in public/config.js
  window.globalConfig = Object.fromEntries(globalConfig);
};

/**
 * Change environment variables in SCREAMING_SNAKE_CASE format (e.g. API_URL)
 * to their camelCase equivalent (e.g. apiUrl).
 */
function screamingSnakeCaseToCamelCase(string: string): string {
  return string.toLowerCase().replace(/_([a-z])/g, (match, group1) => group1.toUpperCase());
}
