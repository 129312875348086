import './assets/css/index.css';
import {createApp} from 'vue';
import App from './App.vue';
import globalEnvConfig from './utils/globalEnvConfig';
import {useEnv} from '@/composables/useEnv/useEnv';
import {pinia} from '@/pinia';
import {router} from '@/router';

const {isDefined, setEnv} = useEnv();

(async () => {
  /**
   * Set app global configuration by using env vars
   * or injected globalConfig.
   */
  if (import.meta.env?.PROD) {
    await globalEnvConfig();
  }

  // @ts-expect-error globalConfig is injected by the public/env/env.js
  setEnv(window?.globalConfig);

  if (!isDefined()) {
    throw new Error('Whoops! There is something wrong with at least one of the Environment Variables!');
  }

  createApp(App).use(pinia).use(router).mount('#app');
})();
