import type {ConsumerPortalFieldsParameters} from '@myparcel-frontend/sdk';
import type {ConsumerPortalFields, ShopBranding} from '@myparcel-frontend/types';
import {usePublicSdk} from '@myparcel-frontend/sdk';
import {storeToRefs} from 'pinia';
import {useConfig} from '@/composables/useConfig/useConfig';
import {useBrandingStore} from '@/store/brandingStore/brandingStore';

export const useBranding = () => {
  const brandingStore = useBrandingStore();
  const {branding, logo, currentLanguageFields, fields, hasSocialLinks, hasBanner, hasSocialWidget, bannerUrl} =
    storeToRefs(brandingStore);

  const {config, isDemo} = useConfig();

  // todo - set default params form the config and i18n
  const getBranding = async (params: ConsumerPortalFieldsParameters) => {
    const {sdk} = usePublicSdk();

    let newBranding;
    let newFields;
    const promises: [null | Promise<ShopBranding[]>, null | Promise<ConsumerPortalFields[]>] = [null, null];

    /**
     * Allow refetching demo when demo mode is enabled.
     * This is needed when a customer disables the portal this will then fetch the default branding.
     */
    if (!branding.value || isDemo.value) {
      promises[0] = sdk.getBranding({
        parameters: {
          subdomain: params.subdomain,
        },
      });
    }

    if (!fields.value[params.language] || isDemo.value) {
      promises[1] = sdk.getFields({
        parameters: params,
      });
    }

    try {
      [newBranding, newFields] = await Promise.all(promises);

      if (newBranding) {
        brandingStore.setBranding(newBranding[0]);
      }

      if (newFields) {
        brandingStore.setFields(params.language, newFields[0]);
      }
    } catch (error) {
      // todo add new relic error logging
      brandingStore.setBranding(config.value!.defaultBranding);
      brandingStore.setFields(params.language, config.value!.defaultFields);
    }

    return branding.value;
  };

  // todo - should this overwrite the whole branding?
  const setBranding = (newBranding: ShopBranding) => {
    brandingStore.setBranding(newBranding);
  };

  const setFields = (cc: string, fields: ConsumerPortalFields) => {
    brandingStore.setFields(cc, fields);
  };

  return {
    branding,
    logo,
    fields: currentLanguageFields,
    hasSocialLinks,
    hasBanner,
    hasSocialWidget,
    bannerUrl,
    getBranding,
    setBranding,
    setFields,
  };
};
