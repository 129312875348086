<template>
  <section :class="sizeClasses">
    <slot />
  </section>
</template>

<script setup lang="ts">
import type {ValueOf} from '@myparcel-frontend/types';
import {computed} from 'vue';
import {LayoutSize} from './Layout.types';

const props = defineProps<{size?: ValueOf<typeof LayoutSize>}>();

const sizeClasses = computed(() => {
  switch (props.size) {
    case LayoutSize.Half:
      return `flex-[12_12_100%] md:flex-[12_12_calc(50%-.75rem)] group-[.mobile-active]/app:flex-[12_12_100%]`;
    case LayoutSize.Full:
    default:
      return 'flex-[12_12_100%]';
  }
});
</script>
