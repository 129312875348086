/**
 * This function recolors favicons to the branding accent color :).
 *
 * @param {Number} size - Size of the current icon.
 * @param {String} color - Color.
 */

/* c8 ignore start */
export const recolorFavicon = (size: number, color: string) => {
  // This cannot be tested since the links cannot e found in tests
  if (import.meta.env.MODE === 'test') {
    return;
  }

  const link = document.querySelector(`link[sizes='${size}x${size}']`) as HTMLLinkElement;
  const img = document.createElement('img');
  img.src = link.href;

  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.drawImage(img, 0, 0);
    context.globalCompositeOperation = 'source-in';
    context.fillStyle = color;
    context.fillRect(0, 0, size, size);
    context.fill();

    link.type = 'image/x-icon';
    link.href = canvas.toDataURL();
  };
};
/* c8 ignore end */
