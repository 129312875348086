<template>
  <IconBase v-if="getSocialIcon(name)">
    <component :is="getSocialIcon(name)" />
  </IconBase>
</template>

<script setup lang="ts">
import type {ValueOf} from '@myparcel-frontend/types';
import type {Component} from 'vue';
import {SocialPlatforms} from '@myparcel-frontend/types';
import {
  IconBase,
  InstagramSocial,
  FacebookSocial,
  LinkedinSocial,
  TwitterSocial,
  YoutubeSocial,
  TiktokSocial,
} from '@myparcel-frontend/ui';
import {markRaw} from 'vue';

defineProps<{
  name: ValueOf<typeof SocialPlatforms>;
}>();

function getSocialIcon(social: ValueOf<typeof SocialPlatforms>): Component | undefined {
  switch (social) {
    case SocialPlatforms.Facebook:
      return markRaw(FacebookSocial);
    case SocialPlatforms.Instagram:
      return markRaw(InstagramSocial);
    case SocialPlatforms.Twitter:
      return markRaw(TwitterSocial);
    case SocialPlatforms.Linkedin:
      return markRaw(LinkedinSocial);
    case SocialPlatforms.Youtube:
      return markRaw(YoutubeSocial);
    case SocialPlatforms.Tiktok:
      return markRaw(TiktokSocial);
  }
}
</script>
