import type {ReturnShipment} from '@myparcel-frontend/types';
import {usePublicSdk} from '@myparcel-frontend/sdk';
import {useMemoize} from '@vueuse/core';

const cache = new Map<string, Promise<ReturnShipment>>();

export const useReturnShipment = () => {
  // todo - add error handeling
  const createReturnShipment = async (barcode: string, postalCode: string, cc: string): Promise<ReturnShipment> => {
    const {sdk} = usePublicSdk();

    // todo - the api only accepts data through the `X-Referer` header
    // the `X-Referer` is used to send data to the api and the order is important
    const response = await sdk.postReturnShipments({
      headers: {
        // todo - replace url with actual url
        'X-Referer': `https://miley.myparcel.me/track-trace/${barcode}/${postalCode}/${cc}`,
      },
    });

    return response[0];
  };

  const clearCache = () => {
    cache.clear();
  };

  // todo - add error handeling
  const getReturnShipment = useMemoize(
    async (hash: string): Promise<ReturnShipment> => {
      const {sdk} = usePublicSdk();
      const response = await sdk.getReturnShipment({
        path: {hash},
      });

      return response[0];
    },
    {
      cache,
    },
  );

  const createReturnLabel = (hash: string, email: string) => {
    const {sdk} = usePublicSdk();
    return sdk.postReturnShipmentLabel({
      body: [
        {
          hash,
          email,
        },
      ],
    });
  };

  return {
    createReturnShipment,
    getReturnShipment,
    createReturnLabel,
    clearCache,
  };
};
