<template>
  <footer class="flex flex-col">
    <div class="gap-4 grid sm:grid-cols-3 tex-sm">
      <div class="text-center">
        <h3 class="block font-bold mb-2">{{ fields.company.name }}</h3>
        <p>{{ fields.company.street }}</p>
        <p>{{ fields.company.postal_code }} {{ fields.company.city }}</p>
      </div>
      <div class="text-center">
        <h3 class="block font-bold mb-2">{{ $t('contact') }}</h3>
        <p>{{ fields.company.phone }}</p>
        <p>{{ fields.company.email }}</p>
      </div>
      <div
        v-if="hasSocialLinks"
        class="text-center">
        <h3 class="block font-bold mb-2">{{ $t('followUs') }}</h3>
        <SocialLinks :social-links="fields.social_links" />
      </div>
    </div>
    <div class="flex flex-col gap-4 my-6 sm:flex-row sm:justify-center text text-black-light text-center text-xs">
      <a
        :href="termsAndConditionsUrl"
        rel="noopener noreferrer"
        target="_blank"
        >{{ $t('termsOfUse') }}</a
      >
      <a
        :href="privacyPolicyUrl"
        rel="noopener noreferrer"
        target="_blank"
        >{{ $t('privacyStatement') }}</a
      >
    </div>
    <div class="border-color-light border-t flex gap-2 justify-center pt-6 tex-sm text-black-light">
      <p>{{ $t('sentWith') }}</p>
      <BaseImage
        :src="getAssetUrl('/auth0/full_logo.svg')"
        alt="MyParcel logo"
        class="w-[100px]" />
    </div>
  </footer>
</template>

<script setup lang="ts">
import {BaseImage} from '@myparcel-frontend/ui';
import SocialLinks from '@/components/SocialLinks/SocialLinks.vue';
import {useBranding} from '@/composables/useBranding/useBranding';
import {useConfig} from '@/composables/useConfig/useConfig';
import {useEnv} from '@/composables/useEnv/useEnv';

const {fields, hasSocialLinks} = useBranding();
const {privacyPolicyUrl, termsAndConditionsUrl} = useConfig();
const {getAssetUrl} = useEnv();
</script>
