/**
 * Get the HostName from the window
 *
 * In non production we want to remove development naming.
 * We need to do this because our dev and testing have other subdomains.
 *
 * @example
 * demoshop.v2.dev.myparcel.me -> demoshop.myparcel.me
 * demoshop.v2.testing.myparcel.me -> demoshop.myparcel.me
 * dev.myparcel.me -> dev.myparcel.me
 * testing.myparcel.me -> testing.myparcel.me
 */
export const getHostName = () => {
  return window.location.hostname.replace(/v2.(dev|testing)\./gm, '');
};

export const hasSubdomain = () => {
  const minHostNameLength = 3;
  return getHostName().split('.').length >= minHostNameLength;
};
