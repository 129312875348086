import type {TrackAndTrace} from '@myparcel-frontend/sdk';
import {usePublicSdk} from '@myparcel-frontend/sdk';
import {useMemoize} from '@vueuse/core';
import {getCurrentLanguage} from '@/i18n';

const cache = new Map<string, Promise<TrackAndTrace | undefined>>();

export const useTrackAndTraces = () => {
  const getTrackAndTraces = useMemoize(
    async (barcode: string, countryCode: string, postalCode: string) => {
      const {sdk} = usePublicSdk();

      const response = await sdk.getTrackTraces({
        parameters: {
          barcode,
          country_code: countryCode,
          ...(postalCode ? {postal_code: postalCode} : {}),
          extra_info: 'delivery_moment;returnable',
          sort: 'asc',
        },
      });

      return response[0] ?? undefined;
    },
    {
      getKey: (barcode: string, countryCode: string, postalCode: string) =>
        `${barcode}-${countryCode}-${postalCode}-${getCurrentLanguage()}`,
      cache,
    },
  );

  return {
    getTrackAndTraces,
    clearCache: () => cache.clear(),
  };
};
