import type {RouteRecordRaw} from 'vue-router';
import {createDemoTrackAndTrace, createReturnReasonSettings, createReturnShipment} from '@myparcel-frontend/testing';
import {dayjs} from '@myparcel-frontend/utils';
import {useConfig} from '@/composables/useConfig/useConfig';
import {useReturnReasons} from '@/composables/useReturnReasons/useReturnReasons';
import {useReturnShipment} from '@/composables/useReturnShipment/useReturnShipment';
import {useSocialShippingLinks} from '@/composables/useSocialShippingLinks/useSocialShippingLinks';
import {useTrackAndTraces} from '@/composables/useTrackAndTraces/useTrackAndTraces';
import {hasSubdomain} from '@/utils/hosts/subdomain';
import ReturnsPayment from '@/views/Returns/Payment/ReturnsPayment.vue';

// todo - add support for demo routes with mocked data which are only visable in the backoffice
export const routes: RouteRecordRaw[] = [
  {
    name: 'no-subdomain',
    path: '/no-subdomain',
    meta: {
      noSubdomainRequired: true,
    },
    beforeEnter: (to, from, next) => {
      if (hasSubdomain()) {
        return next({name: 'home'});
      }

      next();
    },
    component: () => import('@/views/NoSubdomain/NoSubdomain.vue'),
  },
  {
    name: 'home',
    path: '/',
    component: () => import('@/views/TrackAndTraceSearch/TrackAndTraceSearch.vue'),
  },
  {
    name: 'track-and-trace',
    path: '/track-trace/:barcode/:postalCode?/:cc',
    component: () => import('@/views/TrackAndTrace/TrackAndTrace.vue'),
    beforeEnter: async (to, from, next) => {
      const {linkHasSubdomain} = useConfig();
      const {getTrackAndTraces} = useTrackAndTraces();

      const {barcode, cc, postalCode} = to.params;

      const trackAndTrace = await getTrackAndTraces(barcode, cc, postalCode);

      if (!linkHasSubdomain(trackAndTrace.link_consumer_portal)) {
        return next({
          name: 'home',
        });
      }

      to.meta.trackAndTrace = trackAndTrace;

      next();
    },
    /* c8 ignore start - we cannot test the props function */
    props: (to) => ({
      trackAndTrace: to.meta.trackAndTrace,
      barcode: to.params.barcode,
    }),
    /* c8 ignore end */
  },
  {
    name: 'returns',
    path: '/returns/:hash',
    meta: {
      noSubdomainRequired: true,
    },
    component: () => import('@/views/Returns/ReturnsIndex.vue'),
    /* c8 ignore start - we cannot test the props function */
    props: (to) => ({
      returnShipment: to.meta.returnShipment,
    }),
    /* c8 ignore end */
    redirect: {name: 'returns.overview'},
    // Add the return shipment to all child routes
    beforeEnter: async (to) => {
      const {getReturnShipment} = useReturnShipment();
      const returnShipment = await getReturnShipment(to.params.hash as string);

      to.meta.returnShipment = returnShipment;
    },
    children: [
      // @ts-expect-error - it says we cannot declare props this way but we can
      {
        name: 'returns.overview',
        path: 'overview',
        /* c8 ignore start - we cannot test the props function */
        props: (to) => ({
          returnShipment: to.meta.returnShipment,
          hash: to.params.hash,
        }),
        /* c8 ignore end */
        components: {
          returns: () => import('@/views/Returns/Overview/ReturnsOverview.vue'),
        },
        beforeEnter: async (to, from, next) => {
          if (!to.meta.returnShipment) {
            const {getReturnShipment} = useReturnShipment();
            const returnShipment = await getReturnShipment(to.params.hash as string);
            to.meta.returnShipment = returnShipment;
          }

          if (to.meta.returnShipment.payment_instructions) {
            return next({
              name: 'returns.payment',
              params: {hash: to.params.hash},
            });
          }

          const {getReturnSettings, getReturnReasons} = useReturnReasons();
          const settings = await getReturnSettings(to.params.hash as string);
          const returnReasons = await getReturnReasons(to.params.hash as string);

          to.meta.returnReasonSettings = settings;
          to.meta.returnReasons = returnReasons;

          if (settings.enabled && !returnReasons.length) {
            return next({name: 'returns.reasons', params: {hash: to.params.hash}});
          }

          next();
        },
      },
      // @ts-expect-error - it says we cannot declare props this way but we can
      {
        name: 'returns.payment',
        path: 'payment',
        /* c8 ignore start - we cannot test the props function */
        props: (to) => ({
          hash: to.params.hash,
          returnShipment: to.meta.returnShipment,
        }),
        /* c8 ignore end */
        components: {
          returns: ReturnsPayment,
        },
        beforeEnter: async (to, from, next) => {
          if (!to.meta.returnShipment) {
            const {getReturnShipment} = useReturnShipment();
            const returnShipment = await getReturnShipment(to.params.hash as string);
            to.meta.returnShipment = returnShipment;
          }

          if (!to.meta.returnShipment.payment_instructions) {
            return next({name: 'returns.overview', params: {hash: to.params.hash}});
          }

          next();
        },
      },
      // @ts-expect-error - it says we cannot declare props this way but we can
      {
        name: 'returns.reasons',
        path: 'reasons',
        /* c8 ignore start - we cannot test the props function */
        props: (to) => ({
          hash: to.params.hash,
          returnReasonSettings: to.meta.returnReasonSettings,
        }),
        /* c8 ignore end */
        beforeEnter: async (to, from, next) => {
          const {getReturnSettings, getReturnReasons} = useReturnReasons();
          const settings = await getReturnSettings(to.params.hash as string);
          const returnReasons = await getReturnReasons(to.params.hash as string);

          to.meta.returnReasonSettings = settings;

          if (settings.enabled && !returnReasons.length) {
            return next();
          }

          next({
            name: 'returns.overview',
            params: {hash: to.params.hash},
          });
        },
        components: {
          returns: () => import('@/views/Returns/Reasons/ReturnReasons.vue'),
        },
      },
    ],
  },
  {
    name: 'social-shipping',
    path: '/ship/:uuid',
    component: () => import('@/views/SocialShipping/SocialShipping.vue'),
    beforeEnter: async (to, from, next) => {
      const {getSocialShippingLinks} = useSocialShippingLinks();
      const socialShippingLinks = await getSocialShippingLinks(to.params.uuid);

      to.meta.socialShippingLinks = socialShippingLinks;

      if (dayjs(socialShippingLinks.expires_at.date).isAfter(dayjs())) {
        return next();
      }

      next({
        name: 'home',
      });
    },
    props: (to) => ({
      uuid: to.params.uuid,
      socialShippingLinks: to.meta.socialShippingLinks,
    }),
  },
  {
    name: 'payment.completed',
    path: '/payment_completed/:paymentHash',
    meta: {
      noSubdomainRequired: true,
    },
    redirect: (to) => ({name: 'payment', params: {status: 'completed', paymentHash: to.params.paymentHash}}),
  },
  {
    name: 'payment.canceled',
    path: '/payment_canceled/:paymentHash',
    meta: {
      noSubdomainRequired: true,
    },
    redirect: (to) => ({name: 'payment', params: {status: 'canceled', paymentHash: to.params.paymentHash}}),
  },
  {
    name: 'payment',
    path: '/payment/:status/:paymentHash',
    props: true,
    meta: {
      noSubdomainRequired: true,
    },
    component: () => import('@/views/Payment/Payment.vue'),
  },
  {
    name: 'demo',
    path: '/demo',
    beforeEnter: (to, from, next) => {
      const {isDemo, isAllowedEmbedReferrer} = useConfig();

      if (isDemo.value && isAllowedEmbedReferrer.value) {
        return next();
      }

      next({
        name: 'home',
      });
    },
    meta: {
      noSubdomainRequired: true,
    },
    children: [
      {
        name: 'demo.track-and-trace',
        path: 'track-trace',
        component: () => import('@/views/TrackAndTrace/TrackAndTrace.vue'),
        beforeEnter: (to, from, next) => {
          to.meta.trackAndTrace = createDemoTrackAndTrace();
          next();
        },
        /* c8 ignore start - we cannot test the props function */
        props: (to) => ({
          trackAndTrace: to.meta.trackAndTrace,
          barcode: '3SMYPA000000000',
        }),
        /* c8 ignore end */
      },
      {
        name: 'demo.returns',
        path: 'returns',
        component: () => import('@/views/Returns/ReturnsIndex.vue'),
        /* c8 ignore start - we cannot test the props function */
        props: (to) => ({
          returnShipment: to.meta.returnShipment,
        }),
        /* c8 ignore end */
        // Add the return shipment to all child routes
        beforeEnter: (to) => {
          to.meta.returnShipment = createReturnShipment();
        },
        children: [
          // @ts-expect-error - it says we cannot declare props this way but we can
          {
            name: 'demo.returns.reasons',
            path: 'reasons',
            /* c8 ignore start - we cannot test the props function */
            props: (to) => ({
              hash: 'xX1234567890Xx',
              returnReasonSettings: to.meta.returnReasonSettings,
              returnShipment: to.meta.returnShipment,
            }),
            /* c8 ignore end */
            beforeEnter: (to, from, next) => {
              if (!to.meta.returnShipment) {
                to.meta.returnShipment = createReturnShipment();
              }

              const {settings} = useReturnReasons();

              if (!settings.value) {
                settings.value = createReturnReasonSettings();
              }

              to.meta.returnReasonSettings = settings.value;
              return next();
            },
            components: {
              returns: () => import('@/views/Returns/Reasons/ReturnReasons.vue'),
            },
          },
          // @ts-expect-error - it says we cannot declare props this way but we can
          {
            name: 'demo.returns.overview',
            path: 'overview',
            /* c8 ignore start - we cannot test the props function */
            props: (to) => ({
              hash: 'xX1234567890Xx',
              returnShipment: to.meta.returnShipment,
            }),
            /* c8 ignore end */
            beforeEnter: (to, from, next) => {
              to.meta.returnShipment = createReturnShipment();
              return next();
            },
            components: {
              returns: () => import('@/views/Returns/Overview/ReturnsOverview.vue'),
            },
          },
        ],
      },
    ],
  },
];
