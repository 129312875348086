import {storeToRefs} from 'pinia';
import {useConfigStore} from '@/store/configStore/configStore';

export const useConfig = () => {
  const configStore = useConfigStore();
  const {config, isDemo, subdomain, privacyPolicyUrl, termsAndConditionsUrl, isAllowedEmbedReferrer, mobilePreview} =
    storeToRefs(configStore);

  const linkHasSubdomain = (link: string): boolean => {
    return link.startsWith(`https://${subdomain.value}.myparcel.me`);
  };

  return {
    config,
    isDemo,
    mobilePreview,
    isAllowedEmbedReferrer,
    subdomain,
    privacyPolicyUrl,
    termsAndConditionsUrl,
    linkHasSubdomain,
  };
};
