<template>
  <slot></slot>
</template>

<script setup lang="ts">
import {getCurrentInstance} from 'vue';
import {useConfig} from '@/composables/useConfig/useConfig';
import {createI18nWithLocales} from '@/i18n';

const {config} = useConfig();

const i18n = createI18nWithLocales({
  defaultLanguage: config.value?.defaultLanguage,
  fallbackLanguage: config.value?.fallbackLanguage,
  supportedLanguages: config.value?.supportedLanguages,
});

const instance = getCurrentInstance();

i18n.install(instance.appContext.app);
</script>
