import type {ConfigMyParcel, ConfigSendMyParcel} from '@/types/config.types';
import type {ConfigKey} from '@/types/platform.types';
import type {ValueOf} from '@myparcel-frontend/types';
import _config from '@/assets/config.json';

export const getPlatformConfig = (key: ValueOf<typeof ConfigKey>): ConfigMyParcel | ConfigSendMyParcel => {
  const defaultConfig = _config.default;
  const platformConfig = _config[key] as ConfigMyParcel | ConfigSendMyParcel;
  return {...defaultConfig, ...platformConfig};
};
