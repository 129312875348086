<!-- eslint-disable vue/no-bare-strings-in-template -->
<!-- todo add better error handling for providers -->
<template>
  <div v-if="hasConfig">
    <slot />
  </div>
  <div v-else>No config found for hostname: {{ hostName }}</div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import {useConfig} from '@/composables/useConfig/useConfig';
import {getPlatformConfig} from '@/utils/config/getPlatformConfig';
import {getHostName} from '@/utils/hosts/getHostName';
import {hostNameMatcher} from '@/utils/hosts/hostNameMatcher';

const {config} = useConfig();

const hasConfig = ref(false);
const hostName = getHostName();

try {
  const configKey = hostNameMatcher(hostName);

  if (configKey) {
    const platformConfig = getPlatformConfig(configKey);
    hasConfig.value = true;
    config.value = platformConfig;
  }
} catch (error) {
  // todo add new relic logging
  // keep silent
}
</script>
