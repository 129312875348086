<template>
  <Layout.Widget>
    <Card>
      <h2 class="font-bold pb-2 text-center text-xl">{{ $t('returnPayment.ownContribution.title') }}</h2>
      <p class="inline-block pb-6">
        {{
          $t('returnPayment.ownContribution.description', {
            price: formatters.currency.format(returnShipment.payment_instructions.price.amount),
          })
        }}
      </p>

      <div class="flex gap-4 items-center">
        <Button
          :variant="ButtonVariant.Primary"
          @click="setReturnUrl">
          {{ $t('returnPayment.pay') }}
        </Button>

        <RouterLink
          v-slot="{navigate}"
          :to="{
            name: 'track-and-trace',
            params: {
              barcode: returnShipment.related_shipment_data.barcode,
              postalCode: returnShipment.related_shipment_data.postal_code,
              cc: returnShipment.related_shipment_data.cc,
            },
          }">
          <Button
            :variant="ButtonVariant.PrimaryBasic"
            class="flex gap-1 hover:text-primary-200 justify-center p-0 rounded-none w-full"
            @click="navigate">
            {{ $t('returnPayment.backToTracking') }}
          </Button>
        </RouterLink>
      </div>
    </Card>
  </Layout.Widget>
</template>

<script setup lang="ts">
import type {ReturnShipment} from '@myparcel-frontend/types';
import {Button, ButtonVariant} from '@myparcel-frontend/ui';
import {dayjs, formatters} from '@myparcel-frontend/utils';
import {RouterLink} from 'vue-router';
import Card from '@/components/Card/Card.vue';
import Layout from '@/components/Layout';

const props = defineProps<{
  returnShipment: ReturnShipment;
}>();

const setReturnUrl = () => {
  localStorage.setItem(
    'returnHash',
    JSON.stringify({
      hash: props.returnShipment.hash,
      expires: dayjs().add(1, 'hour').toISOString(),
    }),
  );

  window.location.assign(props.returnShipment.payment_instructions.payment_url);
};
</script>
