import {usePublicSdk} from '@myparcel-frontend/sdk';

export const useSocialShippingLinks = () => {
  const getSocialShippingLinks = async (uuid: string) => {
    const {sdk} = usePublicSdk();

    const [result] = await sdk.getSocialShippingLinks({
      path: {uuid},
    });

    return result;
  };

  return {
    getSocialShippingLinks,
  };
};
