import { openBlock as U, createElementBlock as G, renderSlot as T, createElementVNode as ce, defineComponent as V, onMounted as _e, createBlock as oe, resolveDynamicComponent as me, withCtx as se, h as z, Suspense as pe } from "vue";
var ge = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function fe(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var ae = { exports: {} };
(function(e, s) {
  (function(f, y) {
    e.exports = y();
  })(ge, function() {
    var f = 1e3, y = 6e4, v = 36e5, H = "millisecond", E = "second", w = "minute", D = "hour", h = "day", Y = "week", k = "month", X = "quarter", P = "year", $ = "date", q = "Invalid Date", ie = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, le = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, ue = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(i) {
      var n = ["th", "st", "nd", "rd"], r = i % 100;
      return "[" + i + (n[(r - 20) % 10] || n[r] || n[0]) + "]";
    } }, Z = function(i, n, r) {
      var t = String(i);
      return !t || t.length >= n ? i : "" + Array(n + 1 - t.length).join(r) + i;
    }, de = { s: Z, z: function(i) {
      var n = -i.utcOffset(), r = Math.abs(n), t = Math.floor(r / 60), a = r % 60;
      return (n <= 0 ? "+" : "-") + Z(t, 2, "0") + ":" + Z(a, 2, "0");
    }, m: function i(n, r) {
      if (n.date() < r.date()) return -i(r, n);
      var t = 12 * (r.year() - n.year()) + (r.month() - n.month()), a = n.clone().add(t, k), l = r - a < 0, u = n.clone().add(t + (l ? -1 : 1), k);
      return +(-(t + (r - a) / (l ? a - u : u - a)) || 0);
    }, a: function(i) {
      return i < 0 ? Math.ceil(i) || 0 : Math.floor(i);
    }, p: function(i) {
      return { M: k, y: P, w: Y, d: h, D: $, h: D, m: w, s: E, ms: H, Q: X }[i] || String(i || "").toLowerCase().replace(/s$/, "");
    }, u: function(i) {
      return i === void 0;
    } }, A = "en", M = {};
    M[A] = ue;
    var ee = "$isDayjsObject", K = function(i) {
      return i instanceof I || !(!i || !i[ee]);
    }, F = function i(n, r, t) {
      var a;
      if (!n) return A;
      if (typeof n == "string") {
        var l = n.toLowerCase();
        M[l] && (a = l), r && (M[l] = r, a = l);
        var u = n.split("-");
        if (!a && u.length > 1) return i(u[0]);
      } else {
        var c = n.name;
        M[c] = n, a = c;
      }
      return !t && a && (A = a), a || !t && A;
    }, o = function(i, n) {
      if (K(i)) return i.clone();
      var r = typeof n == "object" ? n : {};
      return r.date = i, r.args = arguments, new I(r);
    }, d = de;
    d.l = F, d.i = K, d.w = function(i, n) {
      return o(i, { locale: n.$L, utc: n.$u, x: n.$x, $offset: n.$offset });
    };
    var I = function() {
      function i(r) {
        this.$L = F(r.locale, null, !0), this.parse(r), this.$x = this.$x || r.x || {}, this[ee] = !0;
      }
      var n = i.prototype;
      return n.parse = function(r) {
        this.$d = function(t) {
          var a = t.date, l = t.utc;
          if (a === null) return /* @__PURE__ */ new Date(NaN);
          if (d.u(a)) return /* @__PURE__ */ new Date();
          if (a instanceof Date) return new Date(a);
          if (typeof a == "string" && !/Z$/i.test(a)) {
            var u = a.match(ie);
            if (u) {
              var c = u[2] - 1 || 0, _ = (u[7] || "0").substring(0, 3);
              return l ? new Date(Date.UTC(u[1], c, u[3] || 1, u[4] || 0, u[5] || 0, u[6] || 0, _)) : new Date(u[1], c, u[3] || 1, u[4] || 0, u[5] || 0, u[6] || 0, _);
            }
          }
          return new Date(a);
        }(r), this.init();
      }, n.init = function() {
        var r = this.$d;
        this.$y = r.getFullYear(), this.$M = r.getMonth(), this.$D = r.getDate(), this.$W = r.getDay(), this.$H = r.getHours(), this.$m = r.getMinutes(), this.$s = r.getSeconds(), this.$ms = r.getMilliseconds();
      }, n.$utils = function() {
        return d;
      }, n.isValid = function() {
        return this.$d.toString() !== q;
      }, n.isSame = function(r, t) {
        var a = o(r);
        return this.startOf(t) <= a && a <= this.endOf(t);
      }, n.isAfter = function(r, t) {
        return o(r) < this.startOf(t);
      }, n.isBefore = function(r, t) {
        return this.endOf(t) < o(r);
      }, n.$g = function(r, t, a) {
        return d.u(r) ? this[t] : this.set(a, r);
      }, n.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, n.valueOf = function() {
        return this.$d.getTime();
      }, n.startOf = function(r, t) {
        var a = this, l = !!d.u(t) || t, u = d.p(r), c = function(x, g) {
          var S = d.w(a.$u ? Date.UTC(a.$y, g, x) : new Date(a.$y, g, x), a);
          return l ? S : S.endOf(h);
        }, _ = function(x, g) {
          return d.w(a.toDate()[x].apply(a.toDate("s"), (l ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(g)), a);
        }, m = this.$W, p = this.$M, b = this.$D, N = "set" + (this.$u ? "UTC" : "");
        switch (u) {
          case P:
            return l ? c(1, 0) : c(31, 11);
          case k:
            return l ? c(1, p) : c(0, p + 1);
          case Y:
            var L = this.$locale().weekStart || 0, B = (m < L ? m + 7 : m) - L;
            return c(l ? b - B : b + (6 - B), p);
          case h:
          case $:
            return _(N + "Hours", 0);
          case D:
            return _(N + "Minutes", 1);
          case w:
            return _(N + "Seconds", 2);
          case E:
            return _(N + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, n.endOf = function(r) {
        return this.startOf(r, !1);
      }, n.$set = function(r, t) {
        var a, l = d.p(r), u = "set" + (this.$u ? "UTC" : ""), c = (a = {}, a[h] = u + "Date", a[$] = u + "Date", a[k] = u + "Month", a[P] = u + "FullYear", a[D] = u + "Hours", a[w] = u + "Minutes", a[E] = u + "Seconds", a[H] = u + "Milliseconds", a)[l], _ = l === h ? this.$D + (t - this.$W) : t;
        if (l === k || l === P) {
          var m = this.clone().set($, 1);
          m.$d[c](_), m.init(), this.$d = m.set($, Math.min(this.$D, m.daysInMonth())).$d;
        } else c && this.$d[c](_);
        return this.init(), this;
      }, n.set = function(r, t) {
        return this.clone().$set(r, t);
      }, n.get = function(r) {
        return this[d.p(r)]();
      }, n.add = function(r, t) {
        var a, l = this;
        r = Number(r);
        var u = d.p(t), c = function(p) {
          var b = o(l);
          return d.w(b.date(b.date() + Math.round(p * r)), l);
        };
        if (u === k) return this.set(k, this.$M + r);
        if (u === P) return this.set(P, this.$y + r);
        if (u === h) return c(1);
        if (u === Y) return c(7);
        var _ = (a = {}, a[w] = y, a[D] = v, a[E] = f, a)[u] || 1, m = this.$d.getTime() + r * _;
        return d.w(m, this);
      }, n.subtract = function(r, t) {
        return this.add(-1 * r, t);
      }, n.format = function(r) {
        var t = this, a = this.$locale();
        if (!this.isValid()) return a.invalidDate || q;
        var l = r || "YYYY-MM-DDTHH:mm:ssZ", u = d.z(this), c = this.$H, _ = this.$m, m = this.$M, p = a.weekdays, b = a.months, N = a.meridiem, L = function(g, S, O, j) {
          return g && (g[S] || g(t, l)) || O[S].slice(0, j);
        }, B = function(g) {
          return d.s(c % 12 || 12, g, "0");
        }, x = N || function(g, S, O) {
          var j = g < 12 ? "AM" : "PM";
          return O ? j.toLowerCase() : j;
        };
        return l.replace(le, function(g, S) {
          return S || function(O) {
            switch (O) {
              case "YY":
                return String(t.$y).slice(-2);
              case "YYYY":
                return d.s(t.$y, 4, "0");
              case "M":
                return m + 1;
              case "MM":
                return d.s(m + 1, 2, "0");
              case "MMM":
                return L(a.monthsShort, m, b, 3);
              case "MMMM":
                return L(b, m);
              case "D":
                return t.$D;
              case "DD":
                return d.s(t.$D, 2, "0");
              case "d":
                return String(t.$W);
              case "dd":
                return L(a.weekdaysMin, t.$W, p, 2);
              case "ddd":
                return L(a.weekdaysShort, t.$W, p, 3);
              case "dddd":
                return p[t.$W];
              case "H":
                return String(c);
              case "HH":
                return d.s(c, 2, "0");
              case "h":
                return B(1);
              case "hh":
                return B(2);
              case "a":
                return x(c, _, !0);
              case "A":
                return x(c, _, !1);
              case "m":
                return String(_);
              case "mm":
                return d.s(_, 2, "0");
              case "s":
                return String(t.$s);
              case "ss":
                return d.s(t.$s, 2, "0");
              case "SSS":
                return d.s(t.$ms, 3, "0");
              case "Z":
                return u;
            }
            return null;
          }(g) || u.replace(":", "");
        });
      }, n.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, n.diff = function(r, t, a) {
        var l, u = this, c = d.p(t), _ = o(r), m = (_.utcOffset() - this.utcOffset()) * y, p = this - _, b = function() {
          return d.m(u, _);
        };
        switch (c) {
          case P:
            l = b() / 12;
            break;
          case k:
            l = b();
            break;
          case X:
            l = b() / 3;
            break;
          case Y:
            l = (p - m) / 6048e5;
            break;
          case h:
            l = (p - m) / 864e5;
            break;
          case D:
            l = p / v;
            break;
          case w:
            l = p / y;
            break;
          case E:
            l = p / f;
            break;
          default:
            l = p;
        }
        return a ? l : d.a(l);
      }, n.daysInMonth = function() {
        return this.endOf(k).$D;
      }, n.$locale = function() {
        return M[this.$L];
      }, n.locale = function(r, t) {
        if (!r) return this.$L;
        var a = this.clone(), l = F(r, t, !0);
        return l && (a.$L = l), a;
      }, n.clone = function() {
        return d.w(this.$d, this);
      }, n.toDate = function() {
        return new Date(this.valueOf());
      }, n.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, n.toISOString = function() {
        return this.$d.toISOString();
      }, n.toString = function() {
        return this.$d.toUTCString();
      }, i;
    }(), re = I.prototype;
    return o.prototype = re, [["$ms", H], ["$s", E], ["$m", w], ["$H", D], ["$W", h], ["$M", k], ["$y", P], ["$D", $]].forEach(function(i) {
      re[i[1]] = function(n) {
        return this.$g(n, i[0], i[1]);
      };
    }), o.extend = function(i, n) {
      return i.$i || (i(n, I, o), i.$i = !0), o;
    }, o.locale = F, o.isDayjs = K, o.unix = function(i) {
      return o(1e3 * i);
    }, o.en = M[A], o.Ls = M, o.p = {}, o;
  });
})(ae);
var ye = ae.exports;
const J = /* @__PURE__ */ fe(ye), Q = {
  shop_id: 1,
  id: 1,
  created: "2021-09-29T09:00:00Z",
  modified: "2021-09-29T09:00:00Z",
  credentials: {
    client_id: "client_id",
    client_secret: "client_secret"
  },
  reference_identifier: "reference_identifier",
  state: "state",
  credentiable_id: 1,
  version: "version",
  auto_import: 1,
  credentiable_type: "credentiable_type",
  expires: J().add(1, "year").unix()
}, We = (e = {}) => ({
  ...Q,
  ...e
}), Ge = (e = {}) => ({
  ...Q,
  ...e,
  expires: J().subtract(1, "year").unix()
}), Ve = (e = {}) => ({
  ...Q,
  ...e,
  expires: J().add(1, "week").unix()
}), Ze = {
  id: 206,
  name: "Evening delivery",
  description: "Avondlevering",
  type: {
    id: 2,
    name: "Option"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205178,
      value: 200,
      country: null,
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, Ke = {
  id: 207,
  name: "Morning delivery",
  description: "Ochtendlevering",
  type: {
    id: 2,
    name: "Option"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205660,
      value: 100,
      country: null,
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, Je = {
  id: 1018,
  name: "Dm3 Refund 0-55",
  description: "Dm3 teruggave 0-55",
  type: {
    id: 4,
    name: "Refund"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205383,
      value: 100,
      country: "AT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205386,
      value: 100,
      country: "BE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205389,
      value: 100,
      country: "BG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205392,
      value: 100,
      country: "CY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205395,
      value: 100,
      country: "CZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205398,
      value: 100,
      country: "DE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205401,
      value: 100,
      country: "DK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205404,
      value: 100,
      country: "EE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205407,
      value: 100,
      country: "ES",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205410,
      value: 100,
      country: "FI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205413,
      value: 100,
      country: "FR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205416,
      value: 100,
      country: "GR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205419,
      value: 100,
      country: "HR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205422,
      value: 100,
      country: "HU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205425,
      value: 100,
      country: "IE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205428,
      value: 100,
      country: "IT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205431,
      value: 100,
      country: "LT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205434,
      value: 100,
      country: "LU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205437,
      value: 100,
      country: "LV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205440,
      value: 100,
      country: "MC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205443,
      value: 100,
      country: "MT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205446,
      value: 100,
      country: "NL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205449,
      value: 100,
      country: "PL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205452,
      value: 100,
      country: "PT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205455,
      value: 100,
      country: "RO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205458,
      value: 100,
      country: "SE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205461,
      value: 100,
      country: "SI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205464,
      value: 100,
      country: "SK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, Qe = {
  id: 1019,
  name: "Dm3 Refund 55-105",
  description: "Dm3 teruggave 55-105",
  type: {
    id: 4,
    name: "Refund"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205384,
      value: 200,
      country: "AT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205387,
      value: 200,
      country: "BE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205390,
      value: 200,
      country: "BG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205393,
      value: 200,
      country: "CY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205396,
      value: 200,
      country: "CZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205399,
      value: 200,
      country: "DE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205402,
      value: 200,
      country: "DK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205405,
      value: 200,
      country: "EE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205408,
      value: 200,
      country: "ES",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205411,
      value: 200,
      country: "FI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205414,
      value: 200,
      country: "FR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205417,
      value: 200,
      country: "GR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205420,
      value: 200,
      country: "HR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205423,
      value: 200,
      country: "HU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205426,
      value: 200,
      country: "IE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205429,
      value: 200,
      country: "IT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205432,
      value: 200,
      country: "LT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205435,
      value: 200,
      country: "LU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205438,
      value: 200,
      country: "LV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205441,
      value: 200,
      country: "MC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205444,
      value: 200,
      country: "MT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205447,
      value: 200,
      country: "NL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205450,
      value: 200,
      country: "PL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205453,
      value: 200,
      country: "PT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205456,
      value: 200,
      country: "RO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205459,
      value: 200,
      country: "SE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205462,
      value: 200,
      country: "SI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205465,
      value: 200,
      country: "SK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, Xe = {
  id: 1020,
  name: "Dm3 Refund 105-205",
  description: "Dm3 teruggave 105-205",
  type: {
    id: 4,
    name: "Refund"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205385,
      value: 300,
      country: "AT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205388,
      value: 300,
      country: "BE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205391,
      value: 300,
      country: "BG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205394,
      value: 300,
      country: "CY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205397,
      value: 300,
      country: "CZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205400,
      value: 300,
      country: "DE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205403,
      value: 300,
      country: "DK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205406,
      value: 300,
      country: "EE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205409,
      value: 300,
      country: "ES",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205412,
      value: 300,
      country: "FI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205415,
      value: 300,
      country: "FR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205418,
      value: 300,
      country: "GR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205421,
      value: 300,
      country: "HR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205424,
      value: 300,
      country: "HU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205427,
      value: 300,
      country: "IE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205430,
      value: 300,
      country: "IT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205433,
      value: 300,
      country: "LT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205436,
      value: 300,
      country: "LU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205439,
      value: 300,
      country: "LV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205442,
      value: 300,
      country: "MC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205445,
      value: 300,
      country: "MT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205448,
      value: 300,
      country: "NL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205451,
      value: 300,
      country: "PL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205454,
      value: 300,
      country: "PT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205457,
      value: 300,
      country: "RO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205460,
      value: 300,
      country: "SE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205463,
      value: 300,
      country: "SI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205466,
      value: 300,
      country: "SK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, qe = {
  id: 304,
  name: "Insurance PostNL",
  description: "Verzekerd vanaf 500",
  type: {
    id: 2,
    name: "Option"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205706,
      value: 100,
      country: null,
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, er = {
  id: 305,
  name: "Insurance PostNL EUR 100",
  description: "Verzekerd tot 100",
  type: {
    id: 2,
    name: "Option"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205703,
      value: 1e4,
      country: null,
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, rr = {
  id: 306,
  name: "Insurance PostNL EUR 250",
  description: "Verzekerd tot 250",
  type: {
    id: 2,
    name: "Option"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205704,
      value: 25e3,
      country: null,
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, ar = {
  id: 307,
  name: "Insurance PostNL EUR 500",
  description: "Verzekerd tot 500",
  type: {
    id: 2,
    name: "Option"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205705,
      value: 5e4,
      country: null,
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, nr = {
  id: 311,
  name: "Insurance PostNL EUR 500 Plus NL to BE",
  description: "Verzekerd vanaf 500 NL naar BE",
  type: {
    id: 2,
    name: "Option"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205707,
      value: 300,
      country: "BE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, tr = {
  id: 4906,
  name: "Bundle Max label discount",
  description: "Pakket Max label korting",
  type: "discount",
  category: {
    id: 1016,
    name: "Bundle label price adjustments"
  },
  prices: [
    {
      id: 39945,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1321,
        min: 0,
        max: null,
        number: 0,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39947,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1322,
        min: 0,
        max: null,
        number: 1,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39949,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1323,
        min: 0,
        max: null,
        number: 2,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39951,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1324,
        min: 0,
        max: null,
        number: 3,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39953,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1325,
        min: 0,
        max: null,
        number: 4,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39955,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1326,
        min: 0,
        max: null,
        number: 5,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    }
  ]
}, ir = {
  id: 4900,
  name: "Bundle Start label discount",
  description: "Pakket Start label korting",
  type: "discount",
  category: {
    id: 1016,
    name: "Bundle label price adjustments"
  },
  prices: [
    {
      id: 39861,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1321,
        min: 0,
        max: null,
        number: 0,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39863,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1322,
        min: 0,
        max: null,
        number: 1,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39865,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1323,
        min: 0,
        max: null,
        number: 2,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39867,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1324,
        min: 0,
        max: null,
        number: 3,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39869,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1325,
        min: 0,
        max: null,
        number: 4,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39871,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1326,
        min: 0,
        max: null,
        number: 5,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    }
  ]
}, lr = {
  id: 4902,
  name: "Bundle Plus label discount",
  description: "Pakket Plus label korting",
  type: "discount",
  category: {
    id: 1016,
    name: "Bundle label price adjustments"
  },
  prices: [
    {
      id: 39889,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1321,
        min: 0,
        max: null,
        number: 0,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39891,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1322,
        min: 0,
        max: null,
        number: 1,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39893,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1323,
        min: 0,
        max: null,
        number: 2,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39895,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1324,
        min: 0,
        max: null,
        number: 3,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39897,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1325,
        min: 0,
        max: null,
        number: 4,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39899,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1326,
        min: 0,
        max: null,
        number: 5,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    }
  ]
}, ur = {
  id: 4904,
  name: "Bundle Premium label discount",
  description: "Pakket Premium label korting",
  type: "discount",
  category: {
    id: 1016,
    name: "Bundle label price adjustments"
  },
  prices: [
    {
      id: 39917,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1321,
        min: 0,
        max: null,
        number: 0,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39919,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1322,
        min: 0,
        max: null,
        number: 1,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39921,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1323,
        min: 0,
        max: null,
        number: 2,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39923,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1324,
        min: 0,
        max: null,
        number: 3,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39925,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1325,
        min: 0,
        max: null,
        number: 4,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    },
    {
      id: 39927,
      value: 40,
      country: null,
      platform_id: 1,
      bracket_range: {
        id: 1326,
        min: 0,
        max: null,
        number: 5,
        bracket_id: 1152,
        internal: !0
      },
      tier_range: null
    }
  ]
}, dr = {
  id: 102,
  name: "Shipment EU 1 & 2",
  description: "Pakket EU 1 & 2",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1,
    name: "Pakket EU PostNL"
  },
  prices: [
    {
      id: 205539,
      value: 200,
      country: "AT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205540,
      value: 200,
      country: "BG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205541,
      value: 200,
      country: "CZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205542,
      value: 200,
      country: "DK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205543,
      value: 200,
      country: "EE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205544,
      value: 200,
      country: "FI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205545,
      value: 200,
      country: "GR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205546,
      value: 200,
      country: "HU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205547,
      value: 200,
      country: "IE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205548,
      value: 200,
      country: "IT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205549,
      value: 200,
      country: "LT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205550,
      value: 200,
      country: "LU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205551,
      value: 200,
      country: "LV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205552,
      value: 200,
      country: "PL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205553,
      value: 200,
      country: "PT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205554,
      value: 200,
      country: "RO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205555,
      value: 200,
      country: "SE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205556,
      value: 200,
      country: "SI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205557,
      value: 200,
      country: "SK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205558,
      value: 200,
      country: "CY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205559,
      value: 200,
      country: "MT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205560,
      value: 200,
      country: "HR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205561,
      value: 200,
      country: "MC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1010,
        min: 0,
        max: 2001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205609,
      value: 100,
      country: "AT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205610,
      value: 100,
      country: "BG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205611,
      value: 100,
      country: "CZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205612,
      value: 100,
      country: "DK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205613,
      value: 100,
      country: "EE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205614,
      value: 100,
      country: "FI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205615,
      value: 100,
      country: "GR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205616,
      value: 100,
      country: "HU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205617,
      value: 100,
      country: "IE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205618,
      value: 100,
      country: "IT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205619,
      value: 100,
      country: "LT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205620,
      value: 100,
      country: "LU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205621,
      value: 100,
      country: "LV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205622,
      value: 100,
      country: "PL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205623,
      value: 100,
      country: "PT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205624,
      value: 100,
      country: "RO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205625,
      value: 100,
      country: "SE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205626,
      value: 100,
      country: "SI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205627,
      value: 100,
      country: "SK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205628,
      value: 100,
      country: "CY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205629,
      value: 100,
      country: "MT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205630,
      value: 100,
      country: "HR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205631,
      value: 100,
      country: "MC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1069,
        min: 20001,
        max: 23001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    }
  ]
}, cr = {
  id: 109,
  name: "Shipment DE",
  description: "Pakket DE",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1,
    name: "Pakket EU PostNL"
  },
  prices: [
    {
      id: 205642,
      value: 700,
      country: "DE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1062,
        min: 2001,
        max: 5001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205644,
      value: 600,
      country: "DE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1012,
        min: 10001,
        max: 20001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    }
  ]
}, _r = {
  id: 112,
  name: "Shipment ES",
  description: "Pakket ES",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1,
    name: "Pakket EU PostNL"
  },
  prices: [
    {
      id: 205643,
      value: 500,
      country: "ES",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1068,
        min: 5001,
        max: 10001,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    },
    {
      id: 205645,
      value: 800,
      country: "ES",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: {
        id: 1070,
        min: 23001,
        max: 31501,
        tier: {
          id: 1003,
          name: "weight"
        }
      }
    }
  ]
}, or = {
  id: 6580,
  name: "DHL For You Pakket NL - S",
  description: "DHL For You Pakket NL - Small",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1019,
    name: "MyParcel Pakket NL DHL For You"
  },
  prices: [
    {
      id: 205656,
      value: 100,
      country: "NL",
      platform_id: 1,
      carrier_id: 9,
      bracket_range: null,
      tier_range: null
    }
  ]
}, mr = {
  id: 6581,
  name: "DHL For You Pakket NL - M",
  description: "DHL For You Pakket NL - Medium",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1019,
    name: "MyParcel Pakket NL DHL For You"
  },
  prices: [
    {
      id: 205657,
      value: 300,
      country: "NL",
      platform_id: 1,
      carrier_id: 9,
      bracket_range: null,
      tier_range: null
    }
  ]
}, sr = {
  id: 6589,
  name: "DHL For You Pakket BE - XXL",
  description: "DHL For You Pakket BE - Extra Extra Large",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1020,
    name: "MyParcel Pakket BE DHL For You"
  },
  prices: [
    {
      id: 205658,
      value: 300,
      country: "BE",
      platform_id: 1,
      carrier_id: 9,
      bracket_range: null,
      tier_range: null
    }
  ]
}, pr = {
  id: 6588,
  name: "DHL For You Pakket BE - XL",
  description: "DHL For You Pakket BE - Extra Large",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1020,
    name: "MyParcel Pakket BE DHL For You"
  },
  prices: [
    {
      id: 205659,
      value: 300,
      country: "BE",
      platform_id: 1,
      carrier_id: 9,
      bracket_range: null,
      tier_range: null
    }
  ]
}, gr = {
  id: 6582,
  name: "DHL For You Pakket NL - L",
  description: "DHL For You Pakket NL - Large",
  type: {
    id: 1,
    name: "Shipment"
  },
  category: {
    id: 1019,
    name: "MyParcel Pakket NL DHL For You"
  },
  prices: [
    {
      id: 205698,
      value: 400,
      country: "NL",
      platform_id: 1,
      carrier_id: 9,
      bracket_range: null,
      tier_range: null
    }
  ]
}, fr = {
  id: 1811,
  name: "Weight ROW",
  description: "Gewicht ROW",
  type: {
    id: 8,
    name: "Other"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205179,
      value: 700,
      country: "AE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205180,
      value: 700,
      country: "AF",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205181,
      value: 700,
      country: "AG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205182,
      value: 700,
      country: "AM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205183,
      value: 700,
      country: "AN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205184,
      value: 700,
      country: "AO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205185,
      value: 700,
      country: "AQ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205186,
      value: 700,
      country: "AR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205187,
      value: 700,
      country: "AW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205188,
      value: 700,
      country: "AZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205189,
      value: 700,
      country: "BB",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205190,
      value: 700,
      country: "BD",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205191,
      value: 700,
      country: "BF",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205192,
      value: 700,
      country: "BH",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205193,
      value: 700,
      country: "BI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205194,
      value: 700,
      country: "BJ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205195,
      value: 700,
      country: "BM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205196,
      value: 700,
      country: "BN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205197,
      value: 700,
      country: "BO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205198,
      value: 700,
      country: "BQ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205199,
      value: 700,
      country: "BS",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205200,
      value: 700,
      country: "BT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205201,
      value: 700,
      country: "BW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205202,
      value: 700,
      country: "BZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205203,
      value: 700,
      country: "CD",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205204,
      value: 700,
      country: "CF",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205205,
      value: 700,
      country: "CG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205206,
      value: 700,
      country: "CI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205207,
      value: 700,
      country: "CL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205208,
      value: 700,
      country: "CM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205209,
      value: 700,
      country: "CO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205210,
      value: 700,
      country: "CR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205211,
      value: 700,
      country: "CU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205212,
      value: 700,
      country: "CV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205213,
      value: 700,
      country: "CW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205214,
      value: 700,
      country: "DJ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205215,
      value: 700,
      country: "DM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205216,
      value: 700,
      country: "DO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205217,
      value: 700,
      country: "DZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205218,
      value: 700,
      country: "EC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205219,
      value: 700,
      country: "EG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205220,
      value: 700,
      country: "ER",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205221,
      value: 700,
      country: "ET",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205222,
      value: 700,
      country: "FJ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205223,
      value: 700,
      country: "FK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205224,
      value: 700,
      country: "GA",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205225,
      value: 700,
      country: "GD",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205226,
      value: 700,
      country: "GE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205227,
      value: 700,
      country: "GF",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205228,
      value: 700,
      country: "GH",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205229,
      value: 700,
      country: "GM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205230,
      value: 700,
      country: "GN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205231,
      value: 700,
      country: "GP",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205232,
      value: 700,
      country: "GQ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205233,
      value: 700,
      country: "GT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205234,
      value: 700,
      country: "GW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205235,
      value: 700,
      country: "GY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205236,
      value: 700,
      country: "HN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205237,
      value: 700,
      country: "HT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205238,
      value: 700,
      country: "ID",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205239,
      value: 700,
      country: "IL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205240,
      value: 700,
      country: "IN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205241,
      value: 700,
      country: "IQ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205242,
      value: 700,
      country: "IR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205243,
      value: 700,
      country: "JM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205244,
      value: 700,
      country: "JO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205245,
      value: 700,
      country: "KE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205246,
      value: 700,
      country: "KG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205247,
      value: 700,
      country: "KH",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205248,
      value: 700,
      country: "KI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205249,
      value: 700,
      country: "KM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205250,
      value: 700,
      country: "KN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205251,
      value: 700,
      country: "KR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205252,
      value: 700,
      country: "KW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205253,
      value: 700,
      country: "KY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205254,
      value: 700,
      country: "KZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205255,
      value: 700,
      country: "LA",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205256,
      value: 700,
      country: "LB",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205257,
      value: 700,
      country: "LC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205258,
      value: 700,
      country: "LK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205259,
      value: 700,
      country: "LR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205260,
      value: 700,
      country: "LS",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205261,
      value: 700,
      country: "LY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205262,
      value: 700,
      country: "MA",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205263,
      value: 700,
      country: "MG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205264,
      value: 700,
      country: "ML",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205265,
      value: 700,
      country: "MM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205266,
      value: 700,
      country: "MN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205267,
      value: 700,
      country: "MO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205268,
      value: 700,
      country: "MQ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205269,
      value: 700,
      country: "MR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205270,
      value: 700,
      country: "MS",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205271,
      value: 700,
      country: "MU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205272,
      value: 700,
      country: "MV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205273,
      value: 700,
      country: "MW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205274,
      value: 700,
      country: "MX",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205275,
      value: 700,
      country: "MY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205276,
      value: 700,
      country: "MZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205277,
      value: 700,
      country: "NA",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205278,
      value: 700,
      country: "NC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205279,
      value: 700,
      country: "NE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205280,
      value: 700,
      country: "NG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205281,
      value: 700,
      country: "NI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205282,
      value: 700,
      country: "NP",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205283,
      value: 700,
      country: "NR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205284,
      value: 700,
      country: "OM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205285,
      value: 700,
      country: "PA",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205286,
      value: 700,
      country: "PE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205287,
      value: 700,
      country: "PF",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205288,
      value: 700,
      country: "PG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205289,
      value: 700,
      country: "PH",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205290,
      value: 700,
      country: "PK",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205291,
      value: 700,
      country: "PM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205292,
      value: 700,
      country: "PN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205293,
      value: 700,
      country: "PR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205294,
      value: 700,
      country: "PY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205295,
      value: 700,
      country: "QA",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205296,
      value: 700,
      country: "RE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205297,
      value: 700,
      country: "RU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205298,
      value: 700,
      country: "RW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205299,
      value: 700,
      country: "SA",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205300,
      value: 700,
      country: "SC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205301,
      value: 700,
      country: "SD",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205302,
      value: 700,
      country: "SG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205303,
      value: 700,
      country: "SL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205304,
      value: 700,
      country: "SN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205305,
      value: 700,
      country: "SO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205306,
      value: 700,
      country: "SR",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205307,
      value: 700,
      country: "ST",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205308,
      value: 700,
      country: "SV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205309,
      value: 700,
      country: "SX",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205310,
      value: 700,
      country: "SY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205311,
      value: 700,
      country: "SZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205312,
      value: 700,
      country: "TC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205313,
      value: 700,
      country: "TD",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205314,
      value: 700,
      country: "TG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205315,
      value: 700,
      country: "TH",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205316,
      value: 700,
      country: "TJ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205317,
      value: 700,
      country: "TL",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205318,
      value: 700,
      country: "TM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205319,
      value: 700,
      country: "TN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205320,
      value: 700,
      country: "TO",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205321,
      value: 700,
      country: "TT",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205322,
      value: 700,
      country: "TV",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205323,
      value: 700,
      country: "TW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205324,
      value: 700,
      country: "TZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205325,
      value: 700,
      country: "UG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205326,
      value: 700,
      country: "UY",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205327,
      value: 700,
      country: "UZ",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205328,
      value: 700,
      country: "VC",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205329,
      value: 700,
      country: "VE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205330,
      value: 700,
      country: "VG",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205331,
      value: 700,
      country: "VI",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205332,
      value: 700,
      country: "VN",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205333,
      value: 700,
      country: "VU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205334,
      value: 700,
      country: "WS",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205335,
      value: 700,
      country: "YE",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205336,
      value: 700,
      country: "ZM",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    },
    {
      id: 205337,
      value: 700,
      country: "ZW",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, yr = {
  id: 1801,
  name: "Weight US",
  description: "Gewicht US",
  type: {
    id: 8,
    name: "Other"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205175,
      value: 100,
      country: "US",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
}, br = {
  id: 1802,
  name: "Weight AU",
  description: "Gewicht AU",
  type: {
    id: 8,
    name: "Other"
  },
  category: {
    id: null,
    name: null
  },
  prices: [
    {
      id: 205176,
      value: 100,
      country: "AU",
      platform_id: 1,
      carrier_id: 1,
      bracket_range: null,
      tier_range: null
    }
  ]
};
var R = /* @__PURE__ */ ((e) => (e[e.PostNl = 1] = "PostNl", e[e.Bpost = 2] = "Bpost", e[e.CheapCargo = 3] = "CheapCargo", e[e.Dpd = 4] = "Dpd", e[e.Instabox = 5] = "Instabox", e[e.Dhl = 6] = "Dhl", e[e.Bol = 7] = "Bol", e[e.BolCom = 7] = "BolCom", e[e.Ups = 8] = "Ups", e[e.DhlForYou = 9] = "DhlForYou", e[e.DhlParcelConnect = 10] = "DhlParcelConnect", e[e.DhlEuroPlus = 11] = "DhlEuroPlus", e))(R || {}), ne = /* @__PURE__ */ ((e) => (e.PostNl = "postnl", e.Bpost = "bpost", e.CheapCargo = "cheapcargo", e.Dpd = "dpd", e.Instabox = "instabox", e.Dhl = "dhl", e.Bol = "bol.com", e.BolCom = "bol.com", e.Ups = "ups", e.DhlForYou = "dhlforyou", e.DhlParcelConnect = "dhlparcelconnect", e.DhlEuroPlus = "dhleuroplus", e))(ne || {}), W = /* @__PURE__ */ ((e) => (e[e.MyParcel = 1] = "MyParcel", e[e.Flespakket = 2] = "Flespakket", e[e.SendMyParcel = 3] = "SendMyParcel", e))(W || {});
const be = {
  id: 1,
  description: "customer_marketing",
  name: "customer_marketing",
  role_type: "customer"
}, ke = {
  id: 1,
  account_id: 692,
  username: "unit@myparcel.nl",
  created: "2023-10-19 13:03:24",
  remember_token: null,
  all_shop_access: !0,
  status: "active",
  contact: {
    id: 777,
    gender: "mr",
    first_name: "Pietje",
    last_name: "Puk",
    email: "unit@myparcel.nl",
    phone: "0612345678",
    company: null,
    street: "",
    number: "",
    number_suffix: "",
    box_number: "",
    postal_code: "",
    city: "",
    cc: null,
    street_additional_info: "",
    region: ""
  },
  roles: [be]
}, ve = {
  id: 1,
  contact_id: 777,
  platform_id: 1,
  origin_id: 1,
  status: 1,
  terms_agreed: !0,
  username: "unit@myparcel.nl",
  first_name: "Pietje",
  last_name: "Puk",
  gender: "mr",
  email: "unit@myparcel.nl",
  phone: "0612345678",
  general_settings: {
    affiliate_bcc: 0,
    affiliate_fee: {
      amount: 10,
      currency: "EUR"
    },
    order_mode: 0,
    is_test: 0
  },
  additional_info: {
    coupon: ""
  },
  shipment_estimates: [
    {
      region: "NL",
      package_type: 1,
      estimate: 50
    }
  ],
  delivery_address: null,
  created: "2023-10-19 13:03:24",
  modified: "2023-10-19 13:03:24",
  carrier_references: [],
  contact: {
    id: 777,
    gender: "mr",
    first_name: "Pietje",
    last_name: "Puk",
    email: "unit@myparcel.nl",
    phone: "0612345678",
    company: null,
    street: "",
    number: "",
    number_suffix: "",
    box_number: "",
    postal_code: "",
    city: "",
    cc: null,
    street_additional_info: "",
    region: ""
  },
  shops: [
    {
      id: 1,
      account_id: 692,
      platform_id: 1,
      name: "http://harmful-fund.biz1697713399325",
      hidden: 0,
      billing: {
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        },
        billing_method: 2,
        cycle: "weekly",
        legal_name: "Myparcel B.V.",
        coc: "12345678",
        vat_number: "",
        iban: "",
        eori_number: "",
        vat_tariff: 210,
        vat_type: "general"
      },
      return: {
        link_expires_after: 28,
        bcc: 0,
        use_shop_shipment_options: 0,
        send_tracktrace_email_for_return_shipments: 0,
        email_address_for_tracktrace_return_shipments: null,
        use_custom_description: 0,
        from_address_name: "",
        use_printerless_return: 0,
        settle_printerless_return_cost: 0,
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        }
      },
      delivery_address: {
        gender: "mr",
        first_name: "Pietje",
        last_name: "Puk",
        email: "unit@myparcel.nl",
        phone: "0612345678",
        company: "Myparcel",
        postal_code: "2132WT",
        number: "66",
        street: "Siriusdreef",
        street_additional_info: "",
        city: "Hoofddorp",
        cc: "NL"
      },
      tracktrace: {
        delivery_notification: 0,
        send_track_trace_emails: 0,
        email_on_handed_to_courier: 1,
        carrier_email_basic_notification: 1,
        bcc: 0,
        from_address_email: "unit@myparcel.nl",
        from_address_company: "Myparcel"
      },
      general_settings: {
        auto_save_addresses: 1,
        reminder_email: 0,
        use_logo_label: 0,
        label_format: "A4",
        weight: 1e3,
        preferred_locale: "nl-NL"
      },
      shipment_options: {
        package_type: 1,
        signature: 0,
        only_recipient: 0,
        insurance: {
          amount: 0,
          currency: "EUR"
        },
        large_format: 0,
        age_check: 0,
        return: 0,
        collect: 0,
        drop_off_at_postal_point: 0,
        saturday_delivery: 0,
        same_day_delivery: 0,
        printerless_return: 0,
        extra_assurance: 0,
        hide_sender: 0,
        label_description: null
      },
      created: "2023-10-19 13:03:25",
      modified: "2023-10-19 13:03:25",
      branding: null,
      return_reason_settings: null
    },
    {
      id: 2,
      account_id: 692,
      platform_id: 1,
      name: "secondary-emails-http://harmful-fund.biz1697713399325-1697713410",
      hidden: 0,
      billing: {
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        },
        billing_method: 2,
        cycle: "weekly",
        legal_name: "Myparcel B.V.",
        coc: "12345678",
        vat_number: "",
        iban: "",
        eori_number: "",
        vat_tariff: 210,
        vat_type: "general"
      },
      return: {
        link_expires_after: 28,
        bcc: 0,
        use_shop_shipment_options: 0,
        send_tracktrace_email_for_return_shipments: 0,
        email_address_for_tracktrace_return_shipments: null,
        use_custom_description: 0,
        from_address_name: "",
        use_printerless_return: 0,
        settle_printerless_return_cost: 0,
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        }
      },
      delivery_address: {
        gender: "mr",
        first_name: "Pietje",
        last_name: "Puk",
        email: "unit@myparcel.nl",
        phone: "0612345678",
        company: "Myparcel",
        postal_code: "2132WT",
        number: "66",
        street: "Siriusdreef",
        street_additional_info: "",
        city: "Hoofddorp",
        cc: "NL"
      },
      tracktrace: {
        delivery_notification: 0,
        send_track_trace_emails: 0,
        email_on_handed_to_courier: 1,
        carrier_email_basic_notification: 1,
        bcc: 0,
        from_address_email: "unit@myparcel.nl",
        from_address_company: "Myparcel"
      },
      general_settings: {
        auto_save_addresses: 1,
        reminder_email: 0,
        use_logo_label: 0,
        label_format: "A4",
        weight: 1e3,
        preferred_locale: "nl-NL"
      },
      shipment_options: {
        package_type: 1,
        signature: 0,
        only_recipient: 0,
        insurance: {
          amount: 0,
          currency: "EUR"
        },
        large_format: 0,
        age_check: 0,
        return: 0,
        collect: 0,
        drop_off_at_postal_point: 0,
        saturday_delivery: 0,
        same_day_delivery: 0,
        printerless_return: 0,
        extra_assurance: 0,
        hide_sender: 0,
        label_description: null
      },
      created: "2023-10-19 13:03:30",
      modified: "2023-10-19 13:03:30",
      branding: null,
      return_reason_settings: null
    }
  ],
  users: [ke]
}, kr = (e = {}) => ({
  ...ve,
  ...e
}), he = {
  account_id: 1,
  carrier_id: R.PostNl,
  created: "2023-10-19 13:03:24",
  id: 1,
  modified: "2023-10-19 13:03:24",
  reference_identifier: "reference123456"
}, vr = (e = {}) => ({
  ...he,
  ...e
}), hr = {
  id: 1,
  contact_id: 777,
  platform_id: W.SendMyParcel,
  origin_id: 1,
  status: 1,
  terms_agreed: !0,
  username: "unit@myparcel.nl",
  first_name: "Pietje",
  last_name: "Puk",
  gender: "mr",
  email: "unit@myparcel.nl",
  phone: "0612345678",
  general_settings: {
    affiliate_bcc: 0,
    affiliate_fee: {
      amount: 10,
      currency: "EUR"
    },
    order_mode: 0,
    allow_printerless_return: 1,
    is_test: 0
  },
  additional_info: {
    coupon: ""
  },
  shipment_estimates: [
    {
      region: "NL",
      package_type: 1,
      estimate: 50
    }
  ],
  delivery_address: null,
  created: "2023-10-19 13:03:24",
  modified: "2023-10-19 13:03:24",
  carrier_references: [],
  contact: {
    id: 777,
    gender: "mr",
    first_name: "Pietje",
    last_name: "Puk",
    email: "unit@myparcel.nl",
    phone: "0612345678",
    company: null,
    street: "",
    number: "",
    number_suffix: "",
    box_number: "",
    postal_code: "",
    city: "",
    cc: null,
    street_additional_info: "",
    region: ""
  },
  shops: [
    {
      id: 1,
      account_id: 692,
      platform_id: W.SendMyParcel,
      name: "http://harmful-fund.biz1697713399325",
      hidden: 0,
      billing: {
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        },
        billing_method: 2,
        cycle: "weekly",
        legal_name: "Myparcel B.V.",
        coc: "12345678",
        vat_number: "",
        iban: "",
        eori_number: "",
        vat_tariff: 210,
        vat_type: "general"
      },
      return: {
        link_expires_after: 28,
        bcc: 0,
        use_shop_shipment_options: 0,
        send_tracktrace_email_for_return_shipments: 0,
        email_address_for_tracktrace_return_shipments: null,
        use_custom_description: 0,
        from_address_name: "",
        use_printerless_return: 0,
        settle_printerless_return_cost: 0,
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        }
      },
      delivery_address: {
        gender: "mr",
        first_name: "Pietje",
        last_name: "Puk",
        email: "unit@myparcel.nl",
        phone: "0612345678",
        company: "Myparcel",
        postal_code: "2132WT",
        number: "66",
        street: "Siriusdreef",
        street_additional_info: "",
        city: "Hoofddorp",
        cc: "NL"
      },
      tracktrace: {
        delivery_notification: 0,
        send_track_trace_emails: 0,
        email_on_handed_to_courier: 1,
        carrier_email_basic_notification: 1,
        bcc: 0,
        from_address_email: "unit@myparcel.nl",
        from_address_company: "Myparcel"
      },
      general_settings: {
        auto_save_addresses: 1,
        reminder_email: 0,
        use_logo_label: 0,
        label_format: "A4",
        weight: 1e3,
        preferred_locale: "nl-NL"
      },
      shipment_options: {
        package_type: 1,
        signature: 0,
        only_recipient: 0,
        insurance: {
          amount: 0,
          currency: "EUR"
        },
        large_format: 0,
        age_check: 0,
        return: 0,
        collect: 0,
        drop_off_at_postal_point: 0,
        saturday_delivery: 0,
        same_day_delivery: 0,
        printerless_return: 0,
        extra_assurance: 0,
        hide_sender: 0,
        label_description: null
      },
      created: "2023-10-19 13:03:25",
      modified: "2023-10-19 13:03:25",
      branding: null,
      return_reason_settings: null
    },
    {
      id: 2,
      account_id: 692,
      platform_id: W.SendMyParcel,
      name: "secondary-emails-http://harmful-fund.biz1697713399325-1697713410",
      hidden: 0,
      billing: {
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        },
        billing_method: 2,
        cycle: "weekly",
        legal_name: "Myparcel B.V.",
        coc: "12345678",
        vat_number: "",
        iban: "",
        eori_number: "",
        vat_tariff: 210,
        vat_type: "general"
      },
      return: {
        link_expires_after: 28,
        bcc: 0,
        use_shop_shipment_options: 0,
        send_tracktrace_email_for_return_shipments: 0,
        email_address_for_tracktrace_return_shipments: null,
        use_custom_description: 0,
        from_address_name: "",
        use_printerless_return: 0,
        settle_printerless_return_cost: 0,
        address: {
          email: "unit@myparcel.nl",
          phone: "0612345678",
          company: "Myparcel",
          postal_code: "2132WT",
          number: "66",
          street: "Siriusdreef",
          street_additional_info: "",
          city: "Hoofddorp",
          cc: "NL",
          person: "Pietje Puk"
        }
      },
      delivery_address: {
        gender: "mr",
        first_name: "Pietje",
        last_name: "Puk",
        email: "unit@myparcel.nl",
        phone: "0612345678",
        company: "Myparcel",
        postal_code: "2132WT",
        number: "66",
        street: "Siriusdreef",
        street_additional_info: "",
        city: "Hoofddorp",
        cc: "NL"
      },
      tracktrace: {
        delivery_notification: 0,
        send_track_trace_emails: 0,
        email_on_handed_to_courier: 1,
        carrier_email_basic_notification: 1,
        bcc: 0,
        from_address_email: "unit@myparcel.nl",
        from_address_company: "Myparcel"
      },
      general_settings: {
        auto_save_addresses: 1,
        reminder_email: 0,
        use_logo_label: 0,
        label_format: "A4",
        weight: 1e3,
        preferred_locale: "nl-NL"
      },
      shipment_options: {
        package_type: 1,
        signature: 0,
        only_recipient: 0,
        insurance: {
          amount: 0,
          currency: "EUR"
        },
        large_format: 0,
        age_check: 0,
        return: 0,
        collect: 0,
        drop_off_at_postal_point: 0,
        saturday_delivery: 0,
        same_day_delivery: 0,
        printerless_return: 0,
        extra_assurance: 0,
        hide_sender: 0,
        label_description: null
      },
      created: "2023-10-19 13:03:30",
      modified: "2023-10-19 13:03:30",
      branding: null,
      return_reason_settings: null
    }
  ],
  users: [
    {
      id: 1,
      account_id: 692,
      username: "unit@myparcel.nl",
      created: "2023-10-19 13:03:24",
      remember_token: null,
      all_shop_access: !0,
      status: "active",
      contact: {
        id: 777,
        gender: "mr",
        first_name: "Pietje",
        last_name: "Puk",
        email: "unit@myparcel.nl",
        phone: "0612345678",
        company: null,
        street: "",
        number: "",
        number_suffix: "",
        box_number: "",
        postal_code: "",
        city: "",
        cc: null,
        street_additional_info: "",
        region: ""
      }
    }
  ]
}, C = "unittest", Pe = {
  use_consumer_portal: !0,
  enable_track_trace: !0,
  enable_returns: !0,
  accent_color: "#0f7859",
  subdomain: C,
  logo: "staticv2/images/myparcel-logo-small.svg"
}, Pr = (e = {}) => ({
  ...Pe,
  ...e
}), Sr = {
  carrier_id: R.PostNl,
  configuration: {
    default_cutoff_time: "17:00",
    monday_cutoff_time: "17:00"
  }
}, Mr = {
  carrier_id: R.DhlForYou,
  configuration: {
    default_cutoff_time: "17:00",
    monday_cutoff_time: "17:00"
  }
};
var Se = {
  Main: "main",
  Custom: "custom"
}, Me = {
  Completed: "completed",
  Cancelled: "cancelled",
  Initialized: "initialized",
  Expired: "expired",
  InvalidHash: "invalid_hash"
};
const Le = {
  id: 1,
  label: void 0,
  carrier_id: R.PostNl,
  carrier: {
    id: R.PostNl,
    name: ne.PostNl
  },
  enabled: 1,
  optional: 0,
  primary: 1,
  type: Se.Main,
  subscription_id: void 0,
  options: {
    customerCode: "MYPO",
    customerNumber: "123456",
    customerCollectionLocation: "100123",
    barcodeOptions: { gpType: "CF", gpRange: "4567" },
    serviceLevels: 1
  },
  username: "username",
  password: "password",
  api_key: "a1b2c3d4e5f6g7"
}, Lr = (e = {}) => ({
  ...Le,
  ...e
}), xe = {
  banner_action_title: "",
  banner_action_url: "",
  review_url: "",
  social_widget: {
    type: "none",
    identifier: ""
  },
  social_links: {
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    linkedin: ""
  },
  company: {
    name: "MyParcel",
    cc: "NL",
    street: "Antareslaan 31",
    street_additional_info: "",
    postal_code: "2132JE",
    city: "Hoofddorp",
    email: "info@myparcel.nl",
    phone: "0233030315"
  },
  returns: {
    introduction: "<p>Voldoen de producten niet aan uw verwachtingen? Terugsturen kan!<br />Met het retourlabel kunt u uw bestelling retourneren.</p>"
  },
  banner_button_enabled: "0",
  language: "nl_NL",
  shop_id: 2219,
  banner_path: null
}, xr = (e = {}) => ({
  ...xe,
  ...e
}), Ee = {
  status: Me.Completed,
  reason: "",
  reason_code: "",
  paid: 1,
  payment_initiation_url: "unittest.myparcel.me"
}, Er = (e = {}) => ({
  ...Ee,
  ...e
}), we = {
  enabled: !0,
  mandatory: !1,
  return_reasons: [
    {
      name: "damaged",
      human: "Artikel(en) is/zijn beschadigd"
    },
    {
      name: "changed_my_mind",
      human: "Ik heb me bedacht"
    },
    {
      name: "color_not_as_expected",
      human: "De kleur is anders dan ik had verwacht"
    },
    {
      name: "delivered_too_late",
      human: "Ik heb het artikel niet op tijd ontvangen"
    },
    {
      name: "dont_need_it_anymore",
      human: "Ik heb het artikel niet meer nodig"
    },
    {
      name: "ordered_multiple_products",
      human: "Ik heb meerdere artikelen besteld, de juiste houd ik"
    },
    {
      name: "ordered_wrong_product",
      human: "Ik heb het verkeerde product besteld"
    },
    {
      name: "product_not_as_expected",
      human: "Het artikel komt niet overeen met afbeelding/productinformatie"
    },
    {
      name: "quality_not_as_expected",
      human: "Ik had een hogere kwaliteit verwacht"
    },
    {
      name: "received_wrong_product",
      human: "Verkeerde artikel(en) ontvangen"
    },
    {
      name: "received_wrong_size",
      human: "Ik heb de verkeerde maat/afmeting ontvangen"
    },
    {
      name: "product_cheaper_elsewhere",
      human: "Het artikel is elders goedkoper"
    },
    {
      name: "product_incomplete",
      human: "Het artikel is incompleet"
    },
    {
      name: "product_not_functional",
      human: "Het artikel werkt niet"
    }
  ]
}, wr = (e = {}) => ({
  ...we,
  ...e
}), De = {
  hash: "6e2569a3837fa4b80170be8431665e9c",
  email: {
    email: null,
    name: "Pietje Puk"
  },
  created: {
    date: "2024-06-05 18:01:35.000000",
    timezone_type: 3,
    timezone: "Europe/Amsterdam"
  },
  expire: {
    date: "2024-07-03 18:01:35.000000",
    timezone_type: 3,
    timezone: "Europe/Amsterdam"
  },
  email_label: null,
  has_printerless_return: !1,
  link_consumer_portal: `https://${C}.myparcel.me/returns/6e2569a3837fa4b80170be8431665e9c`,
  carrier: {
    id: 1,
    name: "PostNL",
    location_picker_url: "https://www.postnl.nl/locatiewijzer/"
  },
  payment_instructions: {
    id: "0f2feb188e527a532aef74b2c4915bb269c2fdd1",
    hash: "0f2feb188e527a532aef74b2c4915bb269c2fdd1",
    invoices: {
      id: 9767,
      number: "C240000004",
      status: 101
    },
    type: 2,
    notification_hash: "0c50463782310405ccd334352da8c9250c58e6b7",
    original_notification_hash: "0c50463782310405ccd334352da8c9250c58e6b7",
    paid: 0,
    price: {
      amount: 1266,
      currency: "EUR"
    },
    payment_url: "https://testpayv2.multisafepay.com/connect/823Ie88ANwCdCi80xibPi2xP2s5dZQ8ZKhs/?lang=nl_NL"
  },
  related_shipment_data: {
    barcode: "3SMYPA778022083",
    postal_code: "2134VP",
    cc: "NL"
  }
}, Dr = (e = {}) => ({
  ...De,
  ...e
}), $e = {
  uuid: "667102df-5c43-49cd-9783-d72ed09c2b3a",
  shop_id: 18,
  generated_shipment_id: null,
  shipment_options: {
    reference_identifier: "",
    options: {
      package_type: 1,
      only_recipient: 0,
      collect: 0,
      drop_off_at_postal_point: 0,
      age_check: 0,
      signature: 0,
      return: 0,
      insurance: {
        amount: 0,
        currency: "EUR"
      },
      large_format: 0,
      saturday_delivery: 0,
      label_description: "",
      delivery_type: 2
    },
    physical_properties: {
      weight: 1e3
    },
    carrier: 1,
    contract_id: 1,
    recipient: {
      cc: "NL"
    }
  },
  link_track_trace: null,
  link_consumer_portal: `https://${C}.myparcel.me/returns/6e2569a3837fa4b80170be8431665e9c`,
  expires_at: {
    date: "2024-09-16 15:30:33.000000",
    timezone_type: 3,
    timezone: "Europe/Amsterdam"
  },
  created_at: {
    date: "2024-09-02 15:30:33.000000",
    timezone_type: 3,
    timezone: "Europe/Amsterdam"
  },
  updated_at: {
    date: "2024-09-02 15:30:33.000000",
    timezone_type: 3,
    timezone: "Europe/Amsterdam"
  }
}, $r = (e = {}) => ({
  ...$e,
  ...e
}), Ne = {
  active: !0,
  id: 1,
  bracket_range_id: 1,
  price: 1,
  product_id: 1,
  weight_tier: null
}, Nr = (e) => ({
  ...Ne,
  ...e
}), Tr = () => [
  {
    name: "damaged",
    human: "Artikel(en) is/zijn beschadigd"
  },
  {
    name: "changed_my_mind",
    human: "Ik heb me bedacht"
  },
  {
    name: "color_not_as_expected",
    human: "De kleur is anders dan ik had verwacht"
  },
  {
    name: "delivered_too_late",
    human: "Ik heb het artikel niet op tijd ontvangen"
  },
  {
    name: "dont_need_it_anymore",
    human: "Ik heb het artikel niet meer nodig"
  },
  {
    name: "ordered_multiple_products",
    human: "Ik heb meerdere artikelen besteld, de juiste houd ik"
  },
  {
    name: "ordered_wrong_product",
    human: "Ik heb het verkeerde product besteld"
  },
  {
    name: "product_not_as_expected",
    human: "Het artikel komt niet overeen met afbeelding/productinformatie"
  },
  {
    name: "quality_not_as_expected",
    human: "Ik had een hogere kwaliteit verwacht"
  },
  {
    name: "received_wrong_product",
    human: "Verkeerde artikel(en) ontvangen"
  },
  {
    name: "received_wrong_size",
    human: "Ik heb de verkeerde maat/afmeting ontvangen"
  },
  {
    name: "other",
    human: "Andere reden"
  },
  {
    name: "product_cheaper_elsewhere",
    human: "Het artikel is elders goedkoper"
  },
  {
    name: "product_incomplete",
    human: "Het artikel is incompleet"
  },
  {
    name: "product_not_functional",
    human: "Het artikel werkt niet"
  },
  {
    name: "product_spoiled",
    human: "Het artikel is bedorven"
  },
  {
    name: "product_too_large",
    human: "Het artikel is te groot"
  },
  {
    name: "product_too_small",
    human: "Het artikel is te klein"
  }
], Te = {
  id: 1,
  content: "<p>Globale melding.</p>",
  active: !1,
  additional_filter: "NL",
  message_type: 1,
  created: "2022-11-28 13:09:19",
  modified: "2022-11-29 10:13:14",
  start_date: "2022-11-29 10:13:14",
  notification_type: "info",
  content_nl: "In sommige gevallen worden berichten niet gelezen",
  content_en: "In some cases messages are not read",
  content_fr: "Dans certains cas, les messages ne sont pas lus",
  platform_ids: [1],
  platform_id: 1,
  system_message_id: 1,
  end_date: "2022-12-29 10:13:14"
}, Rr = (e = {}) => ({
  ...Te,
  ...e
}), Re = {
  shipment_id: 15016384,
  carrier: 1,
  code: "I01",
  description: "Zending is bezorgd",
  time: "2024-03-20 14:59:50",
  link_consumer_portal: `https://${C}.myparcel.me/track-trace/3SMYPO337045004/2151DP/NL`,
  link_tracktrace: "https://postnl.nl/tracktrace/?B=3SMYPO337045004&P=2151DP&D=NL&T=C&L=NL",
  partner_tracktraces: [],
  recipient: {
    cc: "NL",
    postal_code: "2132WT",
    city: "Hoofddorp",
    street: "Siriusdreef",
    street_additional_info: "",
    number: "66",
    number_suffix: "",
    person: "Pietje Puk",
    email: "unit@myparcel.nl",
    secondary_emails: null,
    phone: "0612345678",
    eori_number: "",
    vat_number: ""
  },
  sender: {
    email: "unit@myparcel.nl",
    phone: "0634207712",
    company: "Myparcel",
    postal_code: "2132WT",
    number: "66",
    street: "Siriusdreef",
    street_additional_info: "",
    city: "Hoofddorp",
    cc: "NL",
    person: "Pietje Puk",
    number_suffix: "",
    secondary_emails: []
  },
  options: {
    package_type: 1,
    collect: 0,
    only_recipient: 0,
    signature: 0,
    return: 0,
    insurance: {
      amount: 0,
      currency: "EUR"
    },
    large_format: 0,
    age_check: 0,
    saturday_delivery: 0,
    drop_off_at_postal_point: 0,
    label_description: "",
    delivery_type: 2
  },
  pickup: null,
  delayed: !1,
  location: null,
  status: {
    current: 7,
    main: "delivered",
    final: !0
  },
  history: [
    {
      code: "I01",
      status: 7,
      main: null,
      description: "Shipment delivered",
      time: "2024-06-11 12:20:30",
      delayed: !1,
      location: null
    },
    {
      code: "J05",
      status: 5,
      main: "distribution",
      description: "Deliverer en route",
      time: "2024-06-11 08:05:09",
      delayed: !1,
      location: {
        name: "DEPOT NAME HERE",
        cc: null,
        city: null,
        postal_code: null,
        street: null,
        number: null,
        number_suffix: null,
        longitude: null,
        latitude: null
      }
    },
    {
      code: "J01",
      status: 4,
      main: "sorting",
      description: "Shipment sorted at sorting centre",
      time: "2024-06-11 06:43:59",
      delayed: !1,
      location: {
        name: "SORTEERCENTRUM",
        cc: null,
        city: null,
        postal_code: null,
        street: null,
        number: null,
        number_suffix: null,
        longitude: null,
        latitude: null
      }
    },
    {
      code: "B01",
      status: 3,
      main: "handed_to_carrier",
      description: "Shipment at PostNL",
      time: "2024-06-10 16:31:40",
      delayed: !1,
      location: {
        name: "CARRIER LOCATION NAME HERE",
        cc: null,
        city: null,
        postal_code: null,
        street: null,
        number: null,
        number_suffix: null,
        longitude: null,
        latitude: null
      }
    },
    {
      code: "A01",
      status: 2,
      main: "registered",
      description: "The shipment has been registered and will be trackable",
      time: "2024-06-10 13:56:19",
      delayed: !1,
      location: null
    }
  ],
  delivery_moment_type: "estimated",
  delivery_moment: {
    start: {
      date: "2024-06-05 14:00:00.000000",
      timezone_type: 3,
      timezone: "Europe/Amsterdam"
    },
    end: {
      date: "2024-06-05 16:30:00.000000",
      timezone_type: 3,
      timezone: "Europe/Amsterdam"
    }
  },
  returnable: !1
}, He = {
  shipment_id: 15016384,
  carrier: 1,
  code: "S02",
  description: "Zending is bezorgd",
  time: "2024-03-20 14:59:50",
  link_consumer_portal: `https://${C}.myparcel.me/track-trace/3SMYPA000000000/2151DP/NL`,
  link_tracktrace: "https://postnl.nl/tracktrace/?B=3SMYPO337045004&P=2151DP&D=NL&T=C&L=NL",
  partner_tracktraces: [],
  recipient: {
    cc: "NL",
    postal_code: "2132JE",
    city: "Hoofddorp",
    street: "Antareslaan",
    street_additional_info: "",
    number: "31",
    number_suffix: "",
    person: "MyParcel",
    email: "info@myparcel.nl",
    secondary_emails: null,
    phone: "0612345678",
    eori_number: "",
    vat_number: ""
  },
  sender: {
    email: "info@myparcel.nl",
    phone: "0612345678",
    company: "Myparcel",
    postal_code: "2132JE",
    number: "31",
    street: "Antareslaan",
    street_additional_info: "",
    city: "Hoofddorp",
    cc: "NL",
    person: "MyParcel",
    number_suffix: "",
    secondary_emails: []
  },
  options: {
    package_type: 1,
    collect: 0,
    only_recipient: 0,
    signature: 0,
    return: 0,
    insurance: {
      amount: 0,
      currency: "EUR"
    },
    large_format: 0,
    age_check: 0,
    saturday_delivery: 0,
    drop_off_at_postal_point: 0,
    label_description: "",
    delivery_type: 2
  },
  pickup: null,
  delayed: !1,
  location: null,
  status: {
    current: 7,
    main: "delivered",
    final: !0
  },
  history: [
    {
      code: "I01",
      status: 7,
      main: null,
      description: "Shipment delivered",
      time: "2024-06-11 12:20:30",
      delayed: !1,
      location: null
    },
    {
      code: "J05",
      status: 5,
      main: "distribution",
      description: "Deliverer en route",
      time: "2024-06-11 08:05:09",
      delayed: !1,
      location: {
        name: "DEPOT NAME HERE",
        cc: null,
        city: null,
        postal_code: null,
        street: null,
        number: null,
        number_suffix: null,
        longitude: null,
        latitude: null
      }
    },
    {
      code: "J01",
      status: 4,
      main: "sorting",
      description: "Shipment sorted at sorting centre",
      time: "2024-06-11 06:43:59",
      delayed: !1,
      location: {
        name: "SORTEERCENTRUM",
        cc: null,
        city: null,
        postal_code: null,
        street: null,
        number: null,
        number_suffix: null,
        longitude: null,
        latitude: null
      }
    },
    {
      code: "B01",
      status: 3,
      main: "handed_to_carrier",
      description: "Shipment at PostNL",
      time: "2024-06-10 16:31:40",
      delayed: !1,
      location: {
        name: "CARRIER LOCATION NAME HERE",
        cc: null,
        city: null,
        postal_code: null,
        street: null,
        number: null,
        number_suffix: null,
        longitude: null,
        latitude: null
      }
    },
    {
      code: "A01",
      status: 2,
      main: "registered",
      description: "The shipment has been registered and will be trackable",
      time: "2024-06-10 13:56:19",
      delayed: !1,
      location: null
    }
  ],
  delivery_moment_type: "estimated",
  delivery_moment: {
    start: {
      date: "2024-06-05 14:00:00.000000",
      timezone_type: 3,
      timezone: "Europe/Amsterdam"
    },
    end: {
      date: "2024-06-05 16:30:00.000000",
      timezone_type: 3,
      timezone: "Europe/Amsterdam"
    }
  },
  returnable: !1
}, Hr = (e = {}) => ({
  ...Re,
  ...e
}), Ar = (e = {}) => ({
  ...He,
  ...e
}), te = (e, s) => {
  const f = e.__vccOpts || e;
  for (const [y, v] of s)
    f[y] = v;
  return f;
}, Ae = {}, Be = { role: "dialog" };
function Oe(e, s) {
  return U(), G("div", Be, [
    T(e.$slots, "default")
  ]);
}
const Br = /* @__PURE__ */ te(Ae, [["render", Oe]]), Ue = {};
function Ce(e, s) {
  return U(), G("div", null, [
    T(e.$slots, "header"),
    ce("div", null, [
      T(e.$slots, "default")
    ])
  ]);
}
const Or = /* @__PURE__ */ te(Ue, [["render", Ce]]), Ye = { "data-test": "slot-stub" }, Ur = /* @__PURE__ */ V({
  __name: "SlotStub",
  setup(e) {
    return (s, f) => (U(), G("div", Ye, [
      T(s.$slots, "default")
    ]));
  }
}), Fe = { "data-test": "loaded-stub" }, Cr = /* @__PURE__ */ V({
  __name: "LoadedStub",
  emits: ["loaded"],
  setup(e, { emit: s }) {
    const f = s;
    return _e(() => {
      f("loaded");
    }), (y, v) => (U(), G("div", Fe, [
      T(y.$slots, "default")
    ]));
  }
}), Yr = /* @__PURE__ */ V({
  __name: "RouterLinkStub",
  props: {
    to: {},
    tag: { default: "a" },
    exact: { type: Boolean },
    exactPath: { type: Boolean },
    append: { type: Boolean },
    replace: { type: Boolean },
    activeClass: { default: "" },
    exactActiveClass: { default: "" },
    exactPathActiveClass: { default: "" },
    event: { default: "click" }
  },
  setup(e) {
    return (s, f) => (U(), oe(me(s.tag), {
      href: s.tag === "a" && typeof s.to == "string" ? s.to : void 0,
      "data-test": "router-link-stub"
    }, {
      default: se(() => [
        T(s.$slots, "default")
      ]),
      _: 3
    }, 8, ["href"]));
  }
}), Ie = typeof setImmediate == "function" ? setImmediate : setTimeout, Fr = () => new Promise((e) => {
  Ie(e);
}), je = (e) => e.charAt(0).toUpperCase() + e.slice(1), Ir = (e) => V({
  name: "SuspenseWrapper",
  props: e.props,
  emits: e.emits,
  render() {
    var y;
    const s = {
      ...this.$props
    };
    (y = e.emits) == null || y.forEach((v) => {
      s[`on${je(v)}`] = (...H) => this.$emit(v, ...H);
    });
    const f = this.$slots;
    return z(
      "div",
      { id: "root" },
      z(pe, null, {
        default() {
          return z(e, s, f);
        },
        fallback: () => z("div", "fallback")
      })
    );
  }
});
function jr(e) {
  return e.replace(/aria-controls="[^"]+"/g, 'aria-controls="dynamic-id"').replace(/id="[^"]+"/g, 'id="dynamic-id"').replace(/aria-labelledby="[^"]+"/g, 'aria-labelledby="dynamic-id"');
}
export {
  Mr as DHLForYouCarrierConfiguration,
  pr as DHLParcelBeXL,
  sr as DHLParcelBeXXL,
  gr as DHLParcelNlL,
  mr as DHLParcelNlM,
  or as DHLParcelNlS,
  Je as Dm3Refund0_55,
  Xe as Dm3Refund105_205,
  Qe as Dm3Refund55_105,
  Ze as EveningDelivery,
  Cr as LoadedStub,
  Or as MainStub,
  Br as ModalStub,
  Ke as MorningDelivery,
  Sr as PostNLCarrierConfiguration,
  nr as PostNl500ToBe,
  qe as PostNlFrom500,
  er as PostNlTo100,
  rr as PostNlTo250,
  ar as PostNlTo500,
  Yr as RouterLinkStub,
  _r as ShipmenEs,
  cr as ShipmentDe,
  C as ShopBrandingSubdomain,
  Ur as SlotStub,
  br as WeightAU,
  fr as WeightRow,
  yr as WeightUS,
  tr as bundleMaxLabelDiscount,
  lr as bundlePlusLabelDiscount,
  ur as bundlePremiumLabelDiscount,
  ir as bundleStartLabelDiscount,
  Lr as createCarrierOption,
  vr as createCarrierReference,
  xr as createConsumerPortalFields,
  Ar as createDemoTrackAndTrace,
  We as createExternalProvider,
  Ge as createExternalProviderExpired,
  Ve as createExternalProviderExpiringSoon,
  kr as createMyParcelAccount,
  Er as createPaymentStatus,
  Nr as createPrice,
  wr as createReturnReasonSettings,
  Tr as createReturnReasonsMock,
  Dr as createReturnShipment,
  Pr as createShopBranding,
  $r as createSocialShippingLinksMock,
  Rr as createSystemMessage,
  Hr as createTrackAndTrace,
  he as defaultCarrierReference,
  Te as defaultSystemMessage,
  Fr as flushPromises,
  jr as removeDynamicId,
  be as roles,
  hr as sendMyParcelAccount,
  dr as shipmentEU1And2,
  Ir as suspenseWrapper,
  ke as user
};
