import "./chunk-ST4P6AN6.js";
import {
  CarrierId,
  CarrierName,
  isCarrierId,
  isCarrierName,
  isEvent,
  isHtmlElement
} from "./chunk-WUSP3FE4.js";

// src/constants/carrier-options.ts
var CarrierOptionsLabel = {
  AbsentOnDelivery: "absent_on_delivery_note_platform_1",
  DpdNlTest: "DPD NL Test",
  PostNlPhysicalContract: "postnl_physical_contract",
  PostNlPackageSmallNl: "postnl_package_small_nl",
  DhlForYouCollectShipmentsContract: "dhl_for_you_collect_shipments_contract"
};

// src/constants/delivery-type.ts
var DeliveryTypeId = {
  Morning: 1,
  Standard: 2,
  Evening: 3,
  Pickup: 4
};

// src/constants/package-type.ts
var PackageTypId = {
  Package: 1,
  Mailbox: 2,
  Letter: 3,
  DigitalStamp: 4,
  Pallet: 5,
  PackageSmall: 6
};
var PackageTypeName = {
  Package: "package",
  Mailbox: "mailbox",
  Letter: "letter",
  DigitalStamp: "digital_stamp",
  Pallet: "pallet",
  PackageSmall: "package_small"
};

// src/constants/platform.ts
var PlatformId = {
  MyParcel: 1,
  Flespakket: 2,
  SendMyParcel: 3
};

// src/constants/shipment-status.ts
var ShipmentStatus = {
  PendingConcept: 1,
  PendingRegistered: 2,
  EnrouteHandedToCarrier: 3,
  EnrouteSorting: 4,
  EnrouteDistribution: 5,
  DeliveredAtCustoms: 6,
  DeliveredAtRecipient: 7,
  DeliveredReadyForPickup: 8,
  DeliveredPackagePickedUp: 9,
  DeliveredReturnShipmentReadyForPickup: 10,
  DeliveredReturnShipmentPackagePickedUp: 11,
  PrintedUnpaidLabel: 12,
  PrintedLetter: 12,
  Credited: 13,
  InactiveCredited: 13,
  PrintedStamp: 14,
  PrintedDigitalStamp: 14,
  Expired: 16,
  Cancelled: 17,
  PrintedUntrackedShipment: 18,
  InactiveConcept: 30,
  InactiveRegistered: 31,
  InactiveEnrouteHandedToCarrier: 32,
  InactiveEnrouteSorting: 33,
  InactiveEnrouteDistribution: 34,
  InactiveEnrouteCustoms: 35,
  InactiveDeliveredAtRecipient: 36,
  InactiveDeliveredReadyForPickup: 37,
  InactiveDeliveredPackagePickedUp: 38,
  CreditRejected: 100,
  CreditApproved: 101
};
var ShipmentStatusPending = [ShipmentStatus.PendingConcept, ShipmentStatus.PendingRegistered];
var ShipmentStatusEnroute = [
  ShipmentStatus.EnrouteHandedToCarrier,
  ShipmentStatus.EnrouteSorting,
  ShipmentStatus.EnrouteDistribution,
  ShipmentStatus.DeliveredAtCustoms
];
var ShipmentStatusDelivered = [
  ShipmentStatus.DeliveredAtRecipient,
  ShipmentStatus.DeliveredReadyForPickup,
  ShipmentStatus.DeliveredPackagePickedUp,
  ShipmentStatus.DeliveredReturnShipmentReadyForPickup,
  ShipmentStatus.DeliveredReturnShipmentPackagePickedUp
];
var ShipmentStatusPrinted = [ShipmentStatus.PrintedLetter, ShipmentStatus.PrintedDigitalStamp];
var ShipmentStatusInactive = [
  ShipmentStatus.InactiveCredited,
  ShipmentStatus.InactiveConcept,
  ShipmentStatus.InactiveRegistered,
  ShipmentStatus.InactiveEnrouteHandedToCarrier,
  ShipmentStatus.InactiveEnrouteSorting,
  ShipmentStatus.InactiveEnrouteDistribution,
  ShipmentStatus.InactiveEnrouteCustoms,
  ShipmentStatus.InactiveDeliveredAtRecipient,
  ShipmentStatus.InactiveDeliveredReadyForPickup,
  ShipmentStatus.InactiveDeliveredPackagePickedUp
];

// src/types/account-message.types.ts
var AccountMessageSpecifics = {
  ACCOUNT_MAX_TOTAL_INVOICE_AMOUNT_REACHED: 1001,
  BOL_SHIP_ORDER_ITEMS_FAILED: 1002,
  BOL_AUTO_IMPORT_DISABLED: 1003,
  WEBHOOK_IMPORT_FAILED: 1004,
  BOL_SHIPMENT_STATUS_UPDATE_FAILED: 1005,
  ETSY_AUTO_IMPORT_DISABLED: 1006,
  ECWID_AUTO_IMPORT_DISABLED: 1007,
  AMAZON_AUTO_IMPORT_DISABLED: 1008,
  EXACT_AUTO_IMPORT_DISABLED: 1009,
  EXACT_DE_AUTO_IMPORT_DISABLED: 1010,
  WIX_AUTO_IMPORT_DISABLED: 1011,
  BLACKLISTED: 1012
};

// src/types/account.types.ts
var ShipmentLabel = {
  Apart: "apart",
  Nested: "nested",
  None: "none"
};
var AccountStatus = {
  New: 1,
  Active: 2,
  Inactive: 3,
  Suspended: 4,
  Unverified: 8,
  Anonymized: 9
};

// src/types/address.types.ts
var AddressType = {
  Recipient: "recipient",
  Collect: "collect"
};

// src/types/analytics-dashboard.types.ts
var AnalyticsDashboardSlug = {
  Shipments: "shipments",
  ShipmentsWeekly: "shipments-weekly",
  ShipmentsT2: "shipments-t2",
  ShipmentsT3: "shipments-t3",
  ShipmentsT4: "shipments-t4",
  Returns: "returns",
  ReturnsWeekly: "returns-weekly",
  ReturnsT2: "returns-t2",
  ReturnsT3: "returns-t3",
  ReturnsT4: "returns-t4",
  Webshops: "webshops",
  WebshopsWeekly: "webshops-weekly",
  WebshopsT2: "webshops-t2",
  WebshopsT3: "webshops-t3",
  WebshopsT4: "webshops-t4",
  TransitTime: "transit-time",
  TransitTimeT2: "transit-time-t2",
  TransitTimeT3: "transit-time-t3",
  TransitTimeT4: "transit-time-t4",
  Surcharges: "surcharges",
  SurchargesT2: "surcharges-t2",
  SurchargesT3: "surcharges-t3",
  SurchargesT4: "surcharges-t4",
  Cases: "cases",
  CasesT2: "cases-t2",
  CasesT3: "cases-t3",
  CasesT4: "cases-t4",
  FinanceT2: "finance-t2",
  FinanceT3: "finance-t3",
  FinanceT4: "finance-t4",
  ShortcomingT2: "shortcoming-t2",
  ShortcomingT3: "shortcoming-t3",
  ShortcomingT4: "shortcoming-t4"
};
var AnalyticsDashboardCategory = {
  Shipments: "shipments",
  Returns: "returns",
  Webshops: "webshops",
  TransitTime: "transit-time",
  Surcharges: "surcharges",
  Cases: "cases",
  Finance: "finance",
  Shortcoming: "shortcoming"
};

// src/types/bank.types.ts
var MandateStatus = {
  Active: "active",
  Pending: "pending",
  Inactive: "inactive"
};
var TransactionStatus = {
  Open: "open",
  Pending: "pending",
  Succes: "succes",
  Failure: "failure",
  Expired: "expired",
  Cancelled: "cancelled"
};
var TransactionType = {
  Create: "create",
  Update: "update",
  Revoke: "revoke"
};

// src/types/baseProduct.types.ts
var ProductType = {
  Discount: "Discount",
  Option: "Option",
  Refund: "Refund",
  Shipment: "Shipment",
  Surcharge: "Surcharge"
};

// src/types/billing.types.ts
var BillingMethodId = {
  Prepaid: 1,
  Invoice: 2,
  DirectDebit: 3,
  PostPaymentDirectDebitPending: 4,
  PrePaymentDirectDebitPending: 5
};
var BillingStatus = {
  Active: "active",
  Available: "available",
  Unavailable: "unavailable",
  Pending: "pending"
};
var BillingCycle = {
  Monthly: "monthly",
  Weekly: "weekly"
};

// src/types/bracket.types.ts
var PostNlBracketName = {
  ParcelEu: "MyParcel Pakket EU PostNL",
  ParcelNl: "MyParcel Pakket NL PostNL",
  PackageSmallNl: "MyParcel Klein Pakket NL PostNL",
  ParcelErs: "MyParcel Pakket ERS PostNL",
  MailboxParcel: "MyParcel Brievenbuspakje PostNL",
  SmpParcelEu: "Belgi\xEB Pakket EU PostNL",
  SmpParceNL: "Belgi\xEB Pakket NL PostNL",
  SmpParcelBe: "Belgi\xEB Pakket BE PostNL",
  FlsParcelEu: "Flespakket Pakket EU PostNL",
  FlsParcelNl: "Flespakket Pakket NL PostNL",
  FlsParcelErs: "Flespakket Pakket ERS PostNL",
  FlsMailboxParcel: "Flespakket Brievenbuspakje PostNL"
};
var DhlForYouBracketName = {
  ParcelNl: "MyParcel Pakket NL DHL For You",
  ParcelBe: "MyParcel Pakket BE DHL For You",
  MailboxParcelNl: "MyParcel Brievenbuspakje NL DHL For You",
  MailboxParcelBe: "MyParcel Brievenbuspakje BE DHL For You",
  ParcelSameDayNl: "MyParcel Pakket NL DHL For You vandaag",
  MailboxParcelSameDayNl: "MyParcel Brievenbuspakje NL DHL For You vandaag"
};
var DhlEuroplusBracketName = {
  ParcelNl: "MyParcel Pakket NL DHL Europlus",
  ParcelEu: "MyParcel Pakket EU DHL Europlus"
};
var DhlParcelConnectBracketName = {
  ParcelEu: "MyParcel Pakket EU DHL Parcel Connect"
};
var DpdBracketName = {
  ParcelNl: "MyParcel Pakket NL DPD",
  ParcelEu: "MyParcel Pakket EU DPD",
  MailboxParcelNL: "MyParcel Brievenbuspakje NL DPD",
  SmpParcelEu: "Belgi\xEB Pakket EU DPD",
  SmpParcelRow: "Belgi\xEB Pakket ROW DPD",
  SmpParcelBe: "Belgi\xEB Pakket BE DPD"
};
var BpostBracketName = {
  ParcelBe: "Belgi\xEB Pakket BE Bpost",
  ParcelEu: "Belgi\xEB Pakket EU Bpost"
};
var SubscriptionName = {
  Subscription: "MyParcel Abonnement zending",
  SmpSubscription: "Belgi\xEB Abonnement zending"
};

// src/types/carrier/contracts.types.ts
var ContractType = {
  Main: "main",
  Custom: "custom"
};

// src/types/consumer-portal/events.ts
var PortalEvents = {
  DemoReady: "demoReady",
  PortalLoaded: "portalLoaded",
  InitDemo: "initDemo",
  UpdateBranding: "updateBranding",
  UpdateFields: "updateFields",
  UpdateReturnSettings: "updateReturnSettings",
  Navigate: "navigate",
  MobileDisplayMode: "mobileDisplayMode",
  Locale: "locale"
};

// src/types/consumer-portal/payment.types.ts
var PaymentStatusName = {
  Completed: "completed",
  Cancelled: "cancelled",
  Initialized: "initialized",
  Expired: "expired",
  InvalidHash: "invalid_hash"
};

// src/types/consumer-portal/track-trace.types.ts
var TrackTraceShipmentStatus = {
  Registered: "registered",
  HandedToCarrier: "handed_to_carrier",
  Sorting: "sorting",
  Distribution: "distribution",
  Delivered: "delivered"
};
var ShipmentStatusNumber = {
  Registered: 0,
  HandedToCarrier: 3,
  Sorting: 4,
  Distribution: 5,
  Delivered: 6
};

// src/types/consumer-portal-fields.types.ts
var SocialWidgetType = {
  None: "none",
  InstagramPost: "instagram_post",
  TwitterTimeline: "twitter_timeline"
};
var SocialPlatforms = {
  Instagram: "instagram",
  Twitter: "twitter",
  Linkedin: "linkedin",
  Facebook: "facebook",
  Youtube: "youtube",
  Tiktok: "tiktok"
};

// src/types/currency.types.ts
var Currency = {
  Euro: "EUR",
  Usd: "USD"
};

// src/types/days.types.ts
var DaysOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};
var DaysOfWeekName = {
  Sunday: "sunday",
  Monday: "monday",
  Tuesday: "tuesday",
  Wednesday: "wednesday",
  Thursday: "thursday",
  Friday: "friday",
  Saturday: "saturday"
};
var DAYS_OF_WEEK_NAMES_TO_IDS = {
  [DaysOfWeekName.Sunday]: DaysOfWeek.Sunday,
  [DaysOfWeekName.Monday]: DaysOfWeek.Monday,
  [DaysOfWeekName.Tuesday]: DaysOfWeek.Tuesday,
  [DaysOfWeekName.Wednesday]: DaysOfWeek.Wednesday,
  [DaysOfWeekName.Thursday]: DaysOfWeek.Thursday,
  [DaysOfWeekName.Friday]: DaysOfWeek.Friday,
  [DaysOfWeekName.Saturday]: DaysOfWeek.Saturday
};
var DAYS_OF_WEEK_IDS_TO_NAMES = {
  [DaysOfWeek.Sunday]: DaysOfWeekName.Sunday,
  [DaysOfWeek.Monday]: DaysOfWeekName.Monday,
  [DaysOfWeek.Tuesday]: DaysOfWeekName.Tuesday,
  [DaysOfWeek.Wednesday]: DaysOfWeekName.Wednesday,
  [DaysOfWeek.Thursday]: DaysOfWeekName.Thursday,
  [DaysOfWeek.Friday]: DaysOfWeekName.Friday,
  [DaysOfWeek.Saturday]: DaysOfWeekName.Saturday
};
var toDayOfWeekName = (dayOfWeek) => {
  return DAYS_OF_WEEK_IDS_TO_NAMES[dayOfWeek];
};
var toDayOfWeekId = (dayOfWeek) => {
  return DAYS_OF_WEEK_NAMES_TO_IDS[dayOfWeek];
};

// src/types/email.types.ts
var EmailTemplateType = {
  Affiliate: 1,
  Return: 2,
  Tracktrace: 3,
  ShipmentLabel: 4,
  TracktraceReturn: 5
};

// src/types/file.types.ts
var FileType = {
  CSV: "text/csv",
  TXT: "text/plain"
};
var FileSizeUnits = {
  Bytes: "B",
  Kilobytes: "KB",
  Megabytes: "MB",
  Gigabytes: "GB",
  Terabytes: "TB"
};
var fileSizeUnits = [
  FileSizeUnits.Bytes,
  FileSizeUnits.Kilobytes,
  FileSizeUnits.Megabytes,
  FileSizeUnits.Gigabytes,
  FileSizeUnits.Terabytes
];

// src/types/fulfilment.types.ts
var FulfilmentMode = {
  Automatic: "automatic",
  Manual: "manual"
};

// src/types/image.types.ts
var ImageError = {
  Invalid: 3415,
  Dimensions: 3440,
  Filetype: 3441,
  Width: 3442,
  Size: 3462
};

// src/types/insights.types.ts
var InsightsDashboardSlug = {
  Shipments: "shipments",
  ShipmentsWeekly: "shipments-weekly",
  ShipmentsT2: "shipments-t2",
  ShipmentsT3: "shipments-t3",
  Returns: "returns",
  ReturnsWeekly: "returns-weekly",
  ReturnsT2: "returns-t2",
  ReturnsT3: "returns-t3",
  Webshops: "webshops",
  WebshopsWeekly: "webshops-weekly",
  WebshopsT2: "webshops-t2",
  WebshopsT3: "webshops-t3",
  TransitTime: "transit-time",
  TransitTimeT2: "transit-time-t2",
  TransitTimeT3: "transit-time-t3",
  Surcharges: "surcharges",
  SurchargesT2: "surcharges-t2",
  SurchargesT3: "surcharges-t3",
  Cases: "cases",
  CasesT2: "cases-t2",
  CasesT3: "cases-t3",
  FinanceT2: "finance-t2",
  FinanceT3: "finance-t3",
  ShortcomingT2: "shortcoming-t2",
  ShortcomingT3: "shortcoming-t3"
};
var InsightsDashboardCategory = {
  Shipments: "shipments",
  Returns: "returns",
  Webshops: "webshops",
  TransitTime: "transit-time",
  Surcharges: "surcharges",
  Cases: "cases",
  Finance: "finance",
  Shortcoming: "shortcoming"
};

// src/types/integrations/provider.types.ts
var ExternalProviderId = {
  Bol: 1,
  Exact: 2,
  Amazon: 6,
  Wix: 8,
  ExactDe: 10,
  Etsy: 11
};
var ExternalProviderName = {
  Bol: "bol_dot_com",
  Wix: "wix",
  Exact: "exact",
  ExactDe: "exact_de",
  Amazon: "amazon",
  Etsy: "etsy"
};

// src/types/invitation.types.ts
var InvitationStatus = {
  Pending: 1,
  Active: 2,
  Blocked: 3
};

// src/types/invoice.types.ts
var InvoiceStatus = {
  Unpaid: 1,
  Due: 101,
  DuePrepayment: 102,
  Reminder1: 106,
  Reminder2: 111,
  Dun: 116,
  Collection: 121,
  Instalments: 126,
  PaymentArrangement: 127,
  Dispute: 128,
  LegalProcedure: 129,
  Insolvency: 130,
  WriteOff: 131,
  Pending: 301,
  Cancelled: 401,
  Paid: 501,
  PaidOnline: 506,
  PaidAutomatic: 511,
  PaidCredit: 597,
  PaidZeroItems: 598,
  InvoiceWith0InvoiceItemsPaidZeroBalance: 599
};
var InvoiceOpenStatus = [
  InvoiceStatus.Due,
  InvoiceStatus.Reminder1,
  InvoiceStatus.Reminder2,
  InvoiceStatus.Dun,
  InvoiceStatus.Collection,
  InvoiceStatus.Instalments
];
var InvoicePaidStatus = [
  InvoiceStatus.Paid,
  InvoiceStatus.PaidOnline,
  InvoiceStatus.PaidAutomatic,
  InvoiceStatus.PaidCredit,
  InvoiceStatus.PaidZeroItems
];
var InvoiceTab = {
  Open: "open",
  Paid: "paid"
};
var InvoiceType = /* @__PURE__ */ ((InvoiceType2) => {
  InvoiceType2[InvoiceType2["Customer"] = 1] = "Customer";
  InvoiceType2[InvoiceType2["Consumer"] = 2] = "Consumer";
  InvoiceType2[InvoiceType2["ConsumerERS"] = 3] = "ConsumerERS";
  return InvoiceType2;
})(InvoiceType || {});

// src/types/notification.types.ts
var NotificationVariant = {
  Info: "info",
  Success: "success",
  Warning: "warning",
  Error: "alert"
};
var NotificationSize = {
  Full: "full",
  Half: "half"
};
var NotificationLocation = {
  Toaster: "toaster"
};

// src/types/order.types.ts
var SortOrder = {
  Asc: "ASC",
  Desc: "DESC"
};

// src/types/physicalProperties.types.ts
var WeightName = {
  Gram: "gram",
  Kilogram: "kilogram"
};
var Weight = {
  Gram: "gr",
  Kilogram: "kg"
};

// src/types/products/product-names.types.ts
var ProductName = {
  // dm3 refund
  Dm3Refund1: "Dm3 Refund 0-55",
  Dm3Refund2: "Dm3 Refund 55-105",
  Dm3Refund3: "Dm3 Refund 105-205",
  Dm3Refund4: "Dm3 Refund 205",
  // dm3 surcharge
  Dm3Surcharge1: "Dm3 Surcharge 0-55",
  Dm3Surcharge2: "Dm3 Surcharge 55-105",
  Dm3Surcharge3: "Dm3 Surcharge 105-205",
  Dm3Surcharge4: "Dm3 Surcharge 205",
  // ERS
  ErsAt: "ERS Shipment AT",
  ErsBe: "ERS Shipment BE",
  ErsBg: "ERS Shipment BG",
  ErsCz: "ERS Shipment CZ",
  ErsDe: "ERS Shipment DE",
  ErsEe: "ERS Shipment EE",
  ErsEs: "ERS Shipment ES",
  ErsFi: "ERS Shipment FI",
  ErsFr: "ERS Shipment FR",
  ErsGb: "ERS Shipment GB",
  ErsGr: "ERS Shipment GR",
  ErsHr: "ERS Shipment HR",
  ErsHu: "ERS Shipment HU",
  ErsIe: "ERS Shipment IE",
  ErsIt: "ERS Shipment IT",
  ErsLt: "ERS Shipment LT",
  ErsLu: "ERS Shipment LU",
  ErsLv: "ERS Shipment LV",
  ErsMt: "ERS Shipment MT",
  ErsPt: "ERS Shipment PT",
  ErsRo: "ERS Shipment RO",
  ErsSi: "ERS Shipment SI",
  ErsSk: "ERS Shipment SK",
  // Insurance
  InsurancePostNl: "Insurance PostNL",
  InsurancePostNlEur100: "Insurance PostNL EUR 100",
  InsurancePostNlEur250: "Insurance PostNL EUR 250",
  InsurancePostNlEur50ToEuRow: "Insurance PostNL EUR 50 to EU & ROW",
  InsurancePostNlEur500PlusNlToBe: "Insurance PostNL EUR 500 Plus NL to BE",
  InsurancePostNlEur500ToEuRow: "Insurance PostNL EUR 500 to EU & ROW",
  InsurancePostNlEur500: "Insurance PostNL EUR 500",
  InsurancePostNlNlToBeEur500: "Insurance PostNL NL to BE EUR 500",
  // Large format
  LargeFormatNl: "Large Format NL",
  LargeFormatEu: "Large Format EU",
  LargeFormatBe: "Large Format BE",
  LargeFormatDe: "Large Format DE",
  LargeFormatFr: "Large Format FR",
  LargeFormatEs: "Large Format ES",
  // Large format refund
  LargeFormatRefundNl: "Large Format Refund NL",
  LargeFormatRefundEu: "Large Format Refund EU",
  LargeFormatRefundBe: "Large Format Refund BE",
  LargeFormatRefundDe: "Large Format Refund DE",
  LargeFormatRefundFr: "Large Format Refund FR",
  LargeFormatRefundEs: "Large Format Refund ES",
  // large format surcharge
  LargeFormatSurchargeNl: "Large Format Surcharge NL",
  LargeFormatSurchargeEu: "Large Format Surcharge EU",
  LargeFormatSurchargeBe: "Large Format Surcharge BE",
  LargeFormatSurchargeDe: "Large Format Surcharge DE",
  LargeFormatSurchargeFr: "Large Format Surcharge FR",
  LargeFormatSurchargeEs: "Large Format Surcharge ES",
  // Shipment
  ShipmentAu: "Shipment AU",
  ShipmentBe: "Shipment BE",
  ShipmentBr: "Shipment BR",
  ShipmentCa: "Shipment CA",
  ShipmentCh: "Shipment CH",
  ShipmentCn: "Shipment CN",
  ShipmentEu3: "Shipment EU3",
  ShipmentGb: "Shipment GB",
  ShipmentHk: "Shipment HK",
  ShipmentJp: "Shipment JP",
  ShipmentNl: "Shipment NL",
  ShipmentNo: "Shipment NO",
  ShipmentNz: "Shipment NZ",
  ShipmentRow: "Shipment ROW",
  ShipmentUs: "Shipment US",
  ShipmentZa: "Shipment ZA",
  // Shipment with weight brackets
  ShipmentEu1And2: "Shipment EU 1 & 2",
  ShipmentEs: "Shipment ES",
  ShipmentDe: "Shipment DE",
  ShipmentFr: "Shipment FR",
  // Gewicht
  WeightAu: "Weight AU",
  WeightBr: "Weight BR",
  WeightCa: "Weight CA",
  WeightCh: "Weight CH",
  WeightCn: "Weight CN",
  WeightEu3: "Weight EU3",
  WeightGb: "Weight GB",
  WeightHk: "Weight HK",
  WeightJp: "Weight JP",
  WeightNo: "Weight NO",
  WeightNz: "Weight NZ",
  WeightRow: "Weight ROW",
  WeightUs: "Weight US",
  WeightZa: "Weight ZA",
  // Shipment options
  AgeCheck: "age_check",
  Signature: "Signature",
  OnlyRecipient: "Only recipient",
  OnlyRecipientSignature: "Only recipient & Signature",
  PrinterlessReturn: "Printerless return",
  // bundles
  BundleMaxLabelFee: "Bundle Max label fee",
  BundleMaxLabelDiscount: "Bundle Max label discount",
  BundleMaxShipmentDiscount: "Bundle Max shipment discount",
  BundlePlusLabelDiscount: "Bundle Plus label discount",
  BundlePlusLabelFee: "Bundle Plus label fee",
  BundlePlusShipmentDiscount: "Bundle Plus shipment discount",
  BundlePremiumLabelDiscount: "Bundle Premium label discount",
  BundlePremiumLabelFee: "Bundle Premium label fee",
  BundlePremiumShipmentDiscount: "Bundle Premium shipment discount",
  BundleStartLabelDiscount: "Bundle Start label discount",
  BundleStartLabelFee: "Bundle Start label fee",
  BundleStartShipmentDiscount: "Bundle Start shipment discount",
  // other
  Brievenbuspakje: "Brievenbuspakje",
  BrievenbuspakjePeakSurcharge: "Brievenbuspakje Peak Surcharge",
  BrievenbuspakjeToeslag: "Brievenbuspakje Toeslag",
  DigitalePostzegel: "Digitale postzegel",
  EnergySurcharge: "Energy surcharge",
  EveningDelivery: "Evening delivery",
  IncorrectConfirmation: "Incorrect Confirmation",
  KortingOphaallocatie: "Korting ophaallocatie",
  ManualProcessing: "Manual Processing",
  ManualProcessingNoContract: "Manual Processing (no contract)",
  ManualProcessingNoContractRefund: "Manual Processing (no contract) refund",
  ManualProcessingRefund: "Manual Processing refund",
  MaximumDimensionsExceeded: "Maximum Dimensions Exceeded",
  MorningDelivery: "Morning delivery",
  MulticolloDiscount: "Multicollo discount",
  NotificationSurcharge: "Notification Surcharge",
  PeakSurcharge: "Peak Surcharge",
  PromotionalDiscount: "Promotional discount",
  UndeliverableEuShipment: "Undeliverable EU Shipment",
  UndeliverableRowShipment: "Undeliverable ROW Shipment",
  WeightSurcharge: "Weight Surcharge",
  WeightSurchargeRefund: "Weight Surcharge refund",
  // DHL For You
  // surcharge dm kg
  DhlSurcharge10To24Dm3To10Kg: "DHL surcharge 10-24 dm3 / to 10 kg",
  DhlSurcharge24To60Dm3To15Kg: "DHL surcharge 24-60 dm3 / to 15 kg",
  DhlSurcharge60To240Dm3To23Kg: "DHL surcharge 60-240 dm3 / to 23 kg",
  DhlSurcharge240To432Dm3To315Kg: "DHL surcharge 240-432 dm3 / to 31.5 kg",
  // dhl for you parcel
  DhlForYouPakketNlS: "DHL For You Pakket NL - S",
  DhlForYouPakketNlM: "DHL For You Pakket NL - M",
  DhlForYouPakketNlL: "DHL For You Pakket NL - L",
  DhlForYouPakketNlXl: "DHL For You Pakket NL - XL",
  DhlForYouPakketNlXxl: "DHL For You Pakket NL - XXL",
  DhlForYouPakketBeS: "DHL For You Pakket BE - S",
  DhlForYouPakketBeM: "DHL For You Pakket BE - M",
  DhlForYouPakketBeL: "DHL For You Pakket BE - L",
  DhlForYouPakketBeXl: "DHL For You Pakket BE - XL",
  DhlForYouPakketBeXxl: "DHL For You Pakket BE - XXL",
  DhlEveningDelivery: "DHL Evening delivery",
  DhlSaturdayDelivery: "DHL Saturday delivery",
  DhlSignatureOnDelivery: "DHL Signature on delivery",
  DhlNotAtNeighbours: "DHL Not at neighbours",
  DhlAgeCheck: "DHL Age check",
  DhlExtraAssurance: "DHL Extra assurance",
  DhlForYouLetterboxParcelNetherlands: "DHL For You Letterbox Parcel Netherlands",
  DhlTodayParcel: "DHL Today (parcel)",
  DhlTodayLetterbox: "DHL Today (letterbox)",
  ImplicitDiscount: "Implicit discount",
  DhlForYouLetterboxParcelBelgium: "DHL For You Letterbox Parcel Belgium",
  DhlSamedayParcel: "DHL Sameday Parcel",
  DhlSamedayLetterboxParcel: "DHL Sameday Letterbox parcel",
  DhlFuelSurcharge: "DHL Fuel surcharge",
  DhlTollSurcharge: "DHL Toll surcharge",
  DhlGoGreenSurcharge: "DHL GoGreen surcharge",
  // DHL Parcel Connect
  DhlParcelConnectParcel: "DHL Parcel Connect parcel",
  DhlExtraLarge: "DHL Extra large",
  DhlHeavyParcel: "DHL Heavy parcel",
  // DHL Europlus
  DhlEuroplusParcel: "DHL Europlus parcel",
  DhlEuroplusParcelNl: "DHL Europlus parcel NL",
  // UPS
  UpsStandardPackage: "UPS Standard Package",
  UpsExpressSaverPackage: "UPS Express Saver Package",
  UpsStandardPackageShipmentPickup: "UPS Standard Package + Shipment pickup",
  UpsExpressSaverPackageShipmentPickup: "UPS Express Saver Package + Shipment pickup",
  UpsProfitMargin: "UPS Profit margin",
  // Bpost
  StandardPackageBe: "Standard package BE",
  StandardPackageFromPickupLocationBe: "Standard package from pickup location BE",
  ShipmentEuBe: "Shipment EU BE",
  RowBe: "ROW BE",
  StandardMailboxBe: "Standard Mailbox BE",
  // DPD
  HomeDeliveryBe: "Home delivery BE",
  HomeDeliveryEu: "Home delivery EU",
  PickupBe: "Pickup BE",
  PickupEu: "Pickup EU",
  PickupReturnBe: "Pickup Return BE",
  PickupReturnEu: "Pickup Return EU",
  HomeDeliveryRow: "Home delivery ROW"
};

// src/types/region.types.ts
var Region = {
  Eu: "EU",
  HomeCountry: "Home Country",
  Row: "ROW",
  Cd: "CD"
};

// src/types/shipment.types.ts
var ShipmentStatusId = {
  PendingConcept: 1,
  PendingRegistered: 2,
  EnRouteHandedToCarrier: 3,
  EnRouteSorting: 4,
  EnRouteDistribution: 5,
  EnRouteCustoms: 6,
  DeliveredAtRecipient: 7,
  DeliveredReadyForPickup: 8,
  DeliveredPackagePickedUp: 9,
  DeliveredReturnShipmentReadyForPickup: 10,
  DeliveredReturnShipmentPackagePickedUp: 11,
  PrintedLetter: 12,
  InactiveCredited: 13,
  PrintedDigitalStamp: 14,
  InactiveConcept: 30,
  InactiveRegistered: 31,
  InactiveEnRouteHandedToCarrier: 32,
  InactiveEnRouteSorting: 33,
  InactiveEnRouteDistribution: 34,
  InactiveEnRouteCustoms: 35,
  InactiveDeliveredAtRecipient: 36,
  InactiveDeliveredReadyForPickup: 37,
  InactiveDeliveredPackagePickedUp: 38
};

// src/types/shippingRule.types.ts
var ShippingRuleType = {
  Standard: "standard",
  Return: "return"
};

// src/types/subscriptions/bundles.types.ts
import { convertEnumValue as convertEnumValue2 } from "@myparcel/constants/helpers";

// src/types/subscriptions/tiers.types.ts
import { convertEnumValue } from "@myparcel/constants/helpers";
var TierName = {
  Light: "light",
  Start: "start",
  Plus: "plus",
  Premium: "premium",
  Max: "max"
};
var TierId = {
  Light: 0,
  Start: 1,
  Plus: 2,
  Premium: 3,
  Max: 4
};
var TierRange = {
  Zero: 0,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5
};
var SubscriptionTierIdToName = {
  [TierId.Light]: TierName.Light,
  [TierId.Start]: TierName.Start,
  [TierId.Plus]: TierName.Plus,
  [TierId.Premium]: TierName.Premium,
  [TierId.Max]: TierName.Max
};
var SubscriptionTierNameToId = {
  [TierName.Light]: TierId.Light,
  [TierName.Start]: TierId.Start,
  [TierName.Plus]: TierId.Plus,
  [TierName.Premium]: TierId.Premium,
  [TierName.Max]: TierId.Max
};
var convertTierIdToName = (id) => {
  return convertEnumValue(SubscriptionTierIdToName, id);
};
var convertTierNameToId = (name) => {
  return convertEnumValue(SubscriptionTierNameToId, name);
};

// src/types/subscriptions/bundles.types.ts
var BundlesProductId = {
  Start: 4800,
  Plus: 4801,
  Premium: 4802,
  Max: 4803
};
var BundlesTierName = {
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max
};
var BUNDLES_IDS_TO_NAMES = {
  [BundlesProductId.Start]: BundlesTierName.Start,
  [BundlesProductId.Plus]: BundlesTierName.Plus,
  [BundlesProductId.Premium]: BundlesTierName.Premium,
  [BundlesProductId.Max]: BundlesTierName.Max
};
var BUNDLES_NAMES_TO_IDS = {
  [BundlesTierName.Start]: BundlesProductId.Start,
  [BundlesTierName.Plus]: BundlesProductId.Plus,
  [BundlesTierName.Premium]: BundlesProductId.Premium,
  [BundlesTierName.Max]: BundlesProductId.Max
};
var toBundlesTierName = (id) => {
  return convertEnumValue2(BUNDLES_IDS_TO_NAMES, id);
};
var toBundlesProductId = (name) => {
  return convertEnumValue2(BUNDLES_NAMES_TO_IDS, name);
};

// src/types/subscriptions/capabilities.types.ts
var SubscriptionFeature = {
  AllowCustomContracts: "allow_custom_contracts",
  AllowBolDotComVvbShipments: "allow_bol_dot_com_vvb_shipments",
  AllowAutoOrderImport: "allow_auto_order_import",
  AllowOrderNotes: "allow_order_notes",
  AllowUnlimitedShipmentsPerOrderLine: "allow_unlimited_shipments_per_order_line",
  AllowOrderInvoice: "allow_order_invoice",
  AllowOrderPicklist: "allow_order_picklist",
  AllowNestedShipmentLabelOnPackingSlip: "allow_nested_shipment_label_on_packing_slip",
  AllowShopLogoOnPackingSlip: "allow_shop_logo_on_packing_slip",
  ExtendedShippingRules: "extended_shipping_rules",
  UnlimitedShippingRules: "unlimited_shipping_rules",
  AllowTier1Analytics: "allow_tier1_analytics",
  AllowTier2Analytics: "allow_tier2_analytics",
  AllowTier3Analytics: "allow_tier3_analytics",
  ShipmentDiscount: "shipment_discount"
};

// src/types/subscriptions/myAnalytics.types.ts
import { convertEnumValue as convertEnumValue3 } from "@myparcel/constants/helpers";
var MyAnalyticsProductId = {
  Start: 4700,
  Plus: 4701,
  Premium: 4702,
  Max: 4703
};
var MyAnalyticsTierName = {
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max
};
var MY_ANALYTICS_IDS_TO_NAMES = {
  [MyAnalyticsProductId.Start]: MyAnalyticsTierName.Start,
  [MyAnalyticsProductId.Plus]: MyAnalyticsTierName.Plus,
  [MyAnalyticsProductId.Premium]: MyAnalyticsTierName.Premium,
  [MyAnalyticsProductId.Max]: MyAnalyticsTierName.Max
};
var MY_ANALYTICS_NAMES_TO_IDS = {
  [MyAnalyticsTierName.Start]: MyAnalyticsProductId.Start,
  [MyAnalyticsTierName.Plus]: MyAnalyticsProductId.Plus,
  [MyAnalyticsTierName.Premium]: MyAnalyticsProductId.Premium,
  [MyAnalyticsTierName.Max]: MyAnalyticsProductId.Max
};
var toMyAnalyticsTierName = (id) => {
  return convertEnumValue3(MY_ANALYTICS_IDS_TO_NAMES, id);
};
var toMyAnalyticsProductId = (name) => {
  return convertEnumValue3(MY_ANALYTICS_NAMES_TO_IDS, name);
};

// src/types/subscriptions/myContracts.types.ts
import { convertEnumValue as convertEnumValue4 } from "@myparcel/constants/helpers";
var MyContractsProductId = {
  Main: 4600,
  Free: 4601,
  Light: 4610,
  Start: 4611,
  Plus: 4612,
  Premium: 4613,
  Max: 4614
};
var MyContractsTierName = {
  Light: TierName.Light,
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max
};
var MY_CONTRACTS_IDS_TO_NAMES = {
  [MyContractsProductId.Light]: MyContractsTierName.Light,
  [MyContractsProductId.Start]: MyContractsTierName.Start,
  [MyContractsProductId.Plus]: MyContractsTierName.Plus,
  [MyContractsProductId.Premium]: MyContractsTierName.Premium,
  [MyContractsProductId.Max]: MyContractsTierName.Max
};
var MY_CONTRACTS_NAMES_TO_IDS = {
  [MyContractsTierName.Light]: MyContractsProductId.Light,
  [MyContractsTierName.Start]: MyContractsProductId.Start,
  [MyContractsTierName.Plus]: MyContractsProductId.Plus,
  [MyContractsTierName.Premium]: MyContractsProductId.Premium,
  [MyContractsTierName.Max]: MyContractsProductId.Max
};
var toMyContractsTierName = (id) => {
  return convertEnumValue4(MY_CONTRACTS_IDS_TO_NAMES, id);
};
var toSubscriptionProductId = (name) => {
  return convertEnumValue4(MY_CONTRACTS_NAMES_TO_IDS, name);
};

// src/types/subscriptions/myOrders.types.ts
import { convertEnumValue as convertEnumValue5 } from "@myparcel/constants/helpers";
var MyOrdersProductId = {
  Start: 4615,
  Plus: 4616,
  Premium: 4617,
  Max: 4618
};
var MyOrdersTierName = {
  Start: TierName.Start,
  Plus: TierName.Plus,
  Premium: TierName.Premium,
  Max: TierName.Max
};
var MY_ORDERS_IDS_TO_NAMES = {
  [MyOrdersProductId.Start]: MyOrdersTierName.Start,
  [MyOrdersProductId.Plus]: MyOrdersTierName.Plus,
  [MyOrdersProductId.Premium]: MyOrdersTierName.Premium,
  [MyOrdersProductId.Max]: MyOrdersTierName.Max
};
var MY_ORDERS_NAMES_TO_IDS = {
  [MyOrdersTierName.Start]: MyOrdersProductId.Start,
  [MyOrdersTierName.Plus]: MyOrdersProductId.Plus,
  [MyOrdersTierName.Premium]: MyOrdersProductId.Premium,
  [MyOrdersTierName.Max]: MyOrdersProductId.Max
};
var toMyOrdersTierName = (id) => {
  return convertEnumValue5(MY_ORDERS_IDS_TO_NAMES, id);
};
var toMyOrdersProductId = (name) => {
  return convertEnumValue5(MY_ORDERS_NAMES_TO_IDS, name);
};

// src/types/subscriptions/subscription.ts
var SubscriptionType = {
  MyContract: "my_contracts",
  MyAnalytics: "my_analytics",
  MyOrders: "my_orders",
  Bundle: "bundle"
};
var SubscriptionStatus = {
  Active: "active",
  Ended: "ended",
  Pending: "pending",
  StartingSoon: "starting_soon",
  EndingSoon: "ending_soon",
  TrialActive: "trial_active",
  TrialEnded: "trial_ended"
};

// src/types/supportCase.types.ts
var SupportCaseStatus = {
  Open: "open",
  Closed: "closed"
};
var SubType = {
  NoValueChosen: "no_value_chosen",
  NotDelivered: "not_delivered",
  MissingContent: "missing_content",
  Damage: "damage",
  Return: "return",
  RecipientIncorrect: "recipient_incorrect",
  NotReceivedRecipient: "not_received_recipient",
  NotReceivedRecipientBbp: "not_received_recipient_bbp",
  ShipmentOtherQuestion: "shipment_other_question",
  PluginQuestion: "plugin_question",
  PluginHelpNeeded: "plugin_help_needed",
  ShipmentExportHelpNeeded: "shipment_export_help_needed",
  OtherSystemQuestion: "other_system_question",
  Products: "products",
  Rates: "rates",
  Packaging: "packaging",
  WebshopOrders: "webshop_orders",
  InvoiceChangeDetails: "invoice_change_details",
  InvoiceOtherQuestion: "invoice_other_question",
  PalletShipmentQuestion: "pallet_shipment_question"
};
var SupportCaseType = {
  MyShipments: 1,
  System: 2,
  ProductsAndPrices: 3,
  MyInvoice: 4,
  Different: 5,
  MyCollectTrip: 6
};
var SupportLanguages = {
  NL: "nl",
  EN: "en",
  FR: "fr",
  IT: "it",
  ES: "es",
  DE: "de"
};
var SupportCaseInteractionType = {
  Text: "Text",
  ChangeStatus: "ChangeStatus",
  EmailMessage: "EmailMessage"
};
var SupportCaseStatusDescription = {
  // Status description values from SF
  New: "Nieuw",
  Closed: "Gesloten",
  CommentRecieved: "Reactie ontvangen",
  WaitingForYou: "In afwachting van jou",
  HandledByCustomerService: "In behandeling"
};

// src/types/system-messages.types.ts
var SystemMessageType = {
  SHIPMENT_FORM: 1,
  DASHBOARD: 2,
  GLOBAL_MESSAGE: 3,
  COLLECTION_TRIP_MESSAGE: 4,
  CASES_OVERVIEW: 5,
  CONTACT: 6
};
var SystemNotificationType = {
  ALERT: "alert",
  WARNING: "warning",
  SUCCESS: "success",
  INFO: "info",
  DEFAULT: "info"
};

// src/types/user.types.ts
var RoleType = {
  Owner: "customer_main",
  Main: "customer_admin",
  Regular: "customer_sub",
  Warehouse: "customer_warehouse",
  Marketing: "customer_marketing",
  CustomerService: "customer_service",
  Financial: "customer_financial"
};

// src/types/webhook.types.ts
var WebhookType = {
  ShipmentLabelCreated: "shipment_label_created",
  ShipmentStatusChange: "shipment_status_change"
};

// src/index.ts
export * from "@myparcel/ts-utils";
export {
  AccountMessageSpecifics,
  AccountStatus,
  AddressType,
  AnalyticsDashboardCategory,
  AnalyticsDashboardSlug,
  BUNDLES_IDS_TO_NAMES,
  BUNDLES_NAMES_TO_IDS,
  BillingCycle,
  BillingMethodId,
  BillingStatus,
  BpostBracketName,
  BundlesProductId,
  BundlesTierName,
  CarrierId,
  CarrierName,
  CarrierOptionsLabel,
  ContractType,
  Currency,
  DAYS_OF_WEEK_IDS_TO_NAMES,
  DAYS_OF_WEEK_NAMES_TO_IDS,
  DaysOfWeek,
  DaysOfWeekName,
  DeliveryTypeId,
  DhlEuroplusBracketName,
  DhlForYouBracketName,
  DhlParcelConnectBracketName,
  DpdBracketName,
  EmailTemplateType,
  ExternalProviderId,
  ExternalProviderName,
  FileSizeUnits,
  FileType,
  FulfilmentMode,
  ImageError,
  InsightsDashboardCategory,
  InsightsDashboardSlug,
  InvitationStatus,
  InvoiceOpenStatus,
  InvoicePaidStatus,
  InvoiceStatus,
  InvoiceTab,
  InvoiceType,
  MY_ANALYTICS_IDS_TO_NAMES,
  MY_ANALYTICS_NAMES_TO_IDS,
  MY_CONTRACTS_IDS_TO_NAMES,
  MY_CONTRACTS_NAMES_TO_IDS,
  MY_ORDERS_IDS_TO_NAMES,
  MY_ORDERS_NAMES_TO_IDS,
  MandateStatus,
  MyAnalyticsProductId,
  MyAnalyticsTierName,
  MyContractsProductId,
  MyContractsTierName,
  MyOrdersProductId,
  MyOrdersTierName,
  NotificationLocation,
  NotificationSize,
  NotificationVariant,
  PackageTypId,
  PackageTypeName,
  PaymentStatusName,
  PlatformId,
  PortalEvents,
  PostNlBracketName,
  ProductName,
  ProductType,
  Region,
  RoleType,
  ShipmentLabel,
  ShipmentStatus,
  ShipmentStatusDelivered,
  ShipmentStatusEnroute,
  ShipmentStatusId,
  ShipmentStatusInactive,
  ShipmentStatusNumber,
  ShipmentStatusPending,
  ShipmentStatusPrinted,
  ShippingRuleType,
  SocialPlatforms,
  SocialWidgetType,
  SortOrder,
  SubType,
  SubscriptionFeature,
  SubscriptionName,
  SubscriptionStatus,
  SubscriptionTierIdToName,
  SubscriptionTierNameToId,
  SubscriptionType,
  SupportCaseInteractionType,
  SupportCaseStatus,
  SupportCaseStatusDescription,
  SupportCaseType,
  SupportLanguages,
  SystemMessageType,
  SystemNotificationType,
  TierId,
  TierName,
  TierRange,
  TrackTraceShipmentStatus,
  TransactionStatus,
  TransactionType,
  WebhookType,
  Weight,
  WeightName,
  convertTierIdToName,
  convertTierNameToId,
  fileSizeUnits,
  isCarrierId,
  isCarrierName,
  isEvent,
  isHtmlElement,
  toBundlesProductId,
  toBundlesTierName,
  toDayOfWeekId,
  toDayOfWeekName,
  toMyAnalyticsProductId,
  toMyAnalyticsTierName,
  toMyContractsTierName,
  toMyOrdersProductId,
  toMyOrdersTierName,
  toSubscriptionProductId
};
