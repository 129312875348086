import type {ConfigMyParcel, ConfigSendMyParcel} from '@/types/config.types';
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import {getCurrentLanguage} from '@/i18n';

export const useConfigStore = defineStore('config', () => {
  const config = ref<ConfigMyParcel | ConfigSendMyParcel>();
  const isDemo = ref(false);
  const mobilePreview = ref(false);

  const setConfig = (newConfig: ConfigMyParcel | ConfigSendMyParcel) => {
    config.value = newConfig;
  };

  const subdomain = computed(() => {
    const splitUrl = window.location.hostname.split('.');
    return splitUrl[0];
  });

  const termsAndConditionsUrl = computed(() => {
    if (!config.value) {
      return '';
    }

    const language = getCurrentLanguage();
    // @ts-expect-error - not all languages are defined in the terms
    return config.value.terms[language] || config.value.terms.default;
  });

  const privacyPolicyUrl = computed(() => {
    if (!config.value) {
      return '';
    }

    const language = getCurrentLanguage();
    // @ts-expect-error - not all languages are defined in the terms
    return config.value?.privacy[language] || config.value.privacy.default;
  });

  const isAllowedEmbedReferrer = computed(() => {
    const allowedReferrers = ['https://backoffice.myparcel.nl/'];
    const regex = /(([a-z]|[0-9])*\.)?backoffice\.(([a-z])*\.)?myparcel\.nl/gm;
    const filteredOrigin = document.referrer.replace(regex, 'backoffice.myparcel.nl');

    return allowedReferrers.includes(filteredOrigin);
  });

  return {
    config,
    isDemo,
    isAllowedEmbedReferrer,
    subdomain,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    mobilePreview,
    setConfig,
  };
});
