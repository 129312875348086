import type {Environment} from '@/types/environment.types';
import type {Ref} from 'vue';
import {computed, ref} from 'vue';
import {ConfigKey} from '@/types/platform.types';
import {getHostName} from '@/utils/hosts/getHostName';
import {hostNameMatcher} from '@/utils/hosts/hostNameMatcher';

const apiUrl = ref('');
const assetsFlespakket = ref('');
const assetsMyparcel = ref('');
const assetsSendmyparcel = ref('');
const devMode = ref(false);

export const useEnv = () => {
  const assetsUrl = computed(() => {
    const hostName = getHostName();
    const configKey = hostNameMatcher(hostName);

    if (configKey === ConfigKey.Flespakket) return assetsFlespakket.value;

    if (configKey === ConfigKey.MyParcelBe) return assetsSendmyparcel.value;

    return assetsMyparcel.value;
  });

  const environment = computed<Environment>(() => {
    return {
      apiUrl: apiUrl.value,
      assetsUrl: assetsUrl.value,
      assetsFlespakket: assetsFlespakket.value,
      assetsMyparcel: assetsMyparcel.value,
      assetsSendmyparcel: assetsSendmyparcel.value,
      devMode: devMode.value,
    };
  });

  /**
   * Loop through the values and set the value to the ref.
   * Empty strings will be ignored and after the first match we will return the value.
   */
  const setValue = (ref: Ref, values: (string | number | boolean)[]): void => {
    values.some((value) => {
      if (typeof value === 'boolean') {
        ref.value = value;
        return true;
      }

      if (typeof value === 'number') {
        ref.value = value;
        return true;
      }

      if (typeof value === 'undefined' || !value.length) {
        return false;
      }

      if (value === 'true') {
        ref.value = true;
        return true;
      }

      if (value === 'false') {
        ref.value = false;
        return true;
      }

      ref.value = value;
      return true;
    });
  };

  const setEnv = (env?: Environment) => {
    const {VITE_API_URL, VITE_ASSETS_FLESPAKKET, VITE_ASSETS_MYPARCEL, VITE_ASSETS_SENDMYPARCEL, VITE_DEV_MODE} =
      import.meta.env;

    setValue(apiUrl, [VITE_API_URL, env?.apiUrl]);
    setValue(assetsFlespakket, [VITE_ASSETS_FLESPAKKET, env?.assetsFlespakket]);
    setValue(assetsMyparcel, [VITE_ASSETS_MYPARCEL, env?.assetsMyparcel]);
    setValue(assetsSendmyparcel, [VITE_ASSETS_SENDMYPARCEL, env?.assetsSendmyparcel]);
    setValue(devMode, [VITE_DEV_MODE, env?.devMode]);
  };

  /**
   * Helper to detect if required environment variables are set.
   *
   * @returns {boolean}
   */
  const isDefined = (): boolean => {
    const requiredVars = ['apiUrl', 'assetsFlespakket', 'assetsMyparcel', 'assetsSendmyparcel'];
    const missingVars = requiredVars.filter((variable) => {
      // @ts-expect-error globalConfig is injected by the public/env/env.js
      return !window.globalConfig[variable]?.length;
    });

    if (missingVars.length > 0) {
      throw new Error(`Missing variables: ${missingVars.join(', ')}`);
    }

    return true;
  };

  const getAssetUrl = (asset: string | undefined | null) => {
    if (!asset) {
      return;
    }

    const startsWithSlash = asset.startsWith('/');

    if (startsWithSlash) {
      return `${assetsUrl.value}${asset}`;
    }

    return `${assetsUrl.value}/${asset}`;
  };

  return {
    environment,
    setEnv,
    isDefined,
    getAssetUrl,
  };
};
